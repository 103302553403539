import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SidePanelService } from '@app/services/side-panel.service';
import { Observable, tap } from 'rxjs';
import { CustomizedSnackbarComponent } from './component/customized-snackbar.component';
import { SnackbarErrorMessage } from './snackbar-message.enum';

// enum Snackbar
enum SnackBarCss {
  Success = 'snackbar-success',
  Failure = 'snackbar-failure',
  Warning = 'snackbar-warning',
  Info = 'snackbar-info',
}

enum NotificationPositionCss {
  WithSidePanel = 'notification-with-side-panel',
  WithoutPanel = 'notification-without-side-panel',
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  notificationClass = 'customized-notification';
  positionCss = NotificationPositionCss.WithSidePanel;
  snackBarRef: MatSnackBarRef<CustomizedSnackbarComponent>;

  constructor(private snackBar: MatSnackBar, private sidePanelService: SidePanelService) {}

  watchForSidePanel(): Observable<boolean> {
    return this.sidePanelService.isSidePanelExpanded$.pipe(
      tap(isExpanded => {
        this.positionCss = isExpanded ? NotificationPositionCss.WithSidePanel : NotificationPositionCss.WithoutPanel;
      })
    );
  }

  success(message: SnackbarErrorMessage | string, duration = 10000) {
    this.openNotification(message, SnackBarCss.Success, duration);
  }

  error(message: SnackbarErrorMessage | string, duration = 10000) {
    this.openNotification(message, SnackBarCss.Failure, duration);
  }

  info(message: SnackbarErrorMessage | string, duration = 10000) {
    this.openNotification(message, SnackBarCss.Info, duration);
  }

  warning(message: SnackbarErrorMessage | string, duration = 10000) {
    this.openNotification(message, SnackBarCss.Warning, duration);
  }

  private openNotification(message: string, cssClass: SnackBarCss, duration: number) {
    this.snackBarRef = this.snackBar.openFromComponent(CustomizedSnackbarComponent, {
      data: message,
      duration: duration,
      panelClass: [`customized-notification`, this.positionCss, cssClass],
      horizontalPosition: 'left',
    });
  }

  dismiss() {
    this.snackBar.dismiss();
  }
}
