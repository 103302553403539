import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { PriorityType } from '@shared/enums/priority-type';

export const PriorityTypeListConst: IRadioButtonOption<PriorityType>[] = [
  {
    label: 'low',
    value: PriorityType.Low,
  },
  {
    label: 'med',
    value: PriorityType.Medium,
  },
  {
    label: 'high',
    value: PriorityType.High,
  },
];
