import { Injectable } from '@angular/core';
import { RestapiService } from '@app/services/restapi.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  constructor(private restApiService: RestapiService) {}

  reloadTimer(): Observable<boolean> {
    return this.restApiService.associate<boolean>(`Timer/refresh`, null);
  }
}
