import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Meta } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { CustomIconService } from '@app/services/custom-icon.service';
import { closeMenus } from '@main-application/boards/store/boards.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { setPreviousRoute } from '@shared/actions/route-state.action';
import { setWindowWidth } from '@shared/actions/window-resize.actions';
import { AuthService } from '@shared/services/auth.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter, map, pairwise } from 'rxjs/operators';
import { environment } from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  environment = environment;
  isAuthenticated = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private metaService: Meta,
    private store: Store<{}>,
    customIconService: CustomIconService,
    iconRegistry: MatIconRegistry
  ) {
    customIconService.registerIcons();
    iconRegistry.setDefaultFontSetClass('material-icons');

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(evt => evt instanceof RoutesRecognized),
        map(evt => evt as RoutesRecognized),
        pairwise()
      )
      .subscribe(([previousEvent, _event]) => {
        if (previousEvent?.state) {
          this.store.dispatch(setPreviousRoute({ previousRoute: previousEvent.urlAfterRedirects }));
        }
      });
  }

  @HostListener('window:click', ['$event'])
  @HostListener('window:contextmenu', ['$event'])
  onClick(event) {
    this.store.dispatch(closeMenus());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.store.dispatch(setWindowWidth({ windowWidth: event.target.innerWidth }));
  }

  ngOnInit() {
    this.store.dispatch(setWindowWidth({ windowWidth: window.innerWidth }));

    this.metaService.addTag(
      {
        name: 'author',
        content: this.environment.ver,
      },
      true
    );
  }
}
