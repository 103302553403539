import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RoutePath } from '@shared/enums/route-path.enum';
import { LoginRouteGuard } from '@shared/guards/login-route.guard';

// Use the AuthGuard for protected routes
const routes: Routes = [
  {
    path: `${RoutePath.LOGIN}`,
    loadChildren: () => import('@app/modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: `${RoutePath.ACCEPT_TOKEN}`,
    loadChildren: () => import('@app/modules/accept-token/accept-token.module').then(m => m.AcceptTokenModule),
  },
  {
    path: `${RoutePath.WIDGET_RESCHEDULE}`,
    loadComponent: () =>
      import('@widgets/reschedule-widget/reschedule-widget.component').then(m => m.RescheduleWidgetComponent),
  },
  {
    path: `${RoutePath.WIDGET_DONE}`,
    loadComponent: () => import('@widgets/done-widget/done-widget.component').then(m => m.DoneWidgetComponent),
  },
  {
    path: `${RoutePath.WIDGET_COMMENT}`,
    loadComponent: () => import('@widgets/comment-widget/comment-widget.component').then(m => m.CommentWidgetComponent),
  },
  {
    path: ``,
    canActivate: [LoginRouteGuard],
    loadChildren: () => import('@main-application/main-application.module').then(m => m.MainApplicationModule),
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
