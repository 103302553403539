import { Injectable } from '@angular/core';
import { RestapiService } from '@app/services/restapi.service';
import { FileSearchQuery, FileUploadModel, Upload } from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadsService {
  constructor(private restApiService: RestapiService) {}

  uploadFile(file: File): Observable<Upload> {
    return this.restApiService.upload<Upload>(`FileUploads/`, file);
  }

  removeFile(fileId: number): Observable<boolean> {
    return this.restApiService.delete<boolean>(`FileUploads/${fileId}`);
  }

  search(query: FileSearchQuery) {
    return this.restApiService.post<FileUploadModel[]>(`FileUploads/search`, query);
  }
}
