import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRoutes } from '@shared/constants/app-routes.const';
import { Router } from '@angular/router';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { mergeMap } from 'rxjs';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private snackbarService: SnackbarService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.oidcSecurityService.getAccessToken().pipe(
      map(token => {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            'X-Correlation-Id': uuidv4(),
          },
        });
      }),
      mergeMap(request =>
        next.handle(request).pipe(
          catchError(err => {
            if (err.status === 401) {
              this.snackbarService.error(SnackbarErrorMessage.UnauthorizedAccess);
              this.router.navigate([AppRoutes.NO_PERMISSION]);
            }
            return throwError(() => err);
          })
        )
      )
    );
  }
}
