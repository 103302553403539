import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidePanelService {
  isSidePanelExpanded$: Observable<boolean>;
  private isSidePanelExpanded = true;
  private isSidePanelExpandedSubject = new BehaviorSubject<boolean>(this.isSidePanelExpanded);

  constructor() {
    this.isSidePanelExpanded$ = this.isSidePanelExpandedSubject.asObservable();
  }

  toggleSidePanel() {
    this.isSidePanelExpanded = !this.isSidePanelExpanded;
    this.isSidePanelExpandedSubject.next(this.isSidePanelExpanded);
  }
}
