import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { SnackbarService } from '../snackbar.service';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-customized-snackbar',
  templateUrl: './customized-snackbar.component.html',
  styleUrls: ['./customized-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomizedSnackbarComponent extends ComponentAbstract implements OnInit, OnDestroy {
  constructor(
    private snackbarService: SnackbarService,
    protected cdr: ChangeDetectorRef,
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
    private router: Router
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationStart),
        tap((event: NavigationStart) => {
          this.snackbarService.dismiss();
        })
      )
      .subscribe();
  }

  dismiss() {
    this.snackbarService.dismiss();
  }

  ngOnDestroy(): void {}
}
