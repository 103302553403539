import { Injectable } from '@angular/core';
import { RestapiService } from '@app/services/restapi.service';
import { PermissionLevelType } from '@shared/enums/permission-level.enum';
import { UserType } from '@shared/enums/user-type';
import { getUserOrVendorList } from '@shared/functions/get-user-list.function';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserClaims } from '@shared/interfaces/user-claims';
import { UserData } from '@shared/interfaces/user-data';
import { UserWithGeneralRole } from '@shared/interfaces/user-with-general-role';
import { InviteRestUserModel, RestUserModel, UpdateRestUserModel } from '@shared/interfaces/user.interface';
import { find } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private restApiService: RestapiService, private cacheService: CacheService) {}

  getCachedUserList(isActive?: boolean): Observable<RestUserModel[]> {
    return this.cacheService
      .getCachedInfo('getUserList', 0, () => this.getUserList())
      .pipe(map(list => (typeof isActive !== 'boolean' ? list : list.filter(e => e.isActive === isActive))));
  }

  getCachedUserOrVendorList(): Observable<IRadioButtonOption<number>[]> {
    return this.getCachedUserList(true).pipe(map(list => getUserOrVendorList(list)));
  }

  getUserList(): Observable<RestUserModel[]> {
    return this.restApiService.getData<RestUserModel[]>(`Users/`);
  }

  getPropertyUserList(propertyId: number): Observable<RestUserModel[]> {
    return this.restApiService.getData<RestUserModel[]>(`Users/property/${propertyId}`);
  }

  expireCashedUserData() {
    this.cacheService.expireCachedInfo('getUserData');
  }
  getCashedUserData(): Observable<UserData> {
    return this.cacheService.getCachedInfo('getUserData', 0, () => this.getUserData());
  }

  getUserData(): Observable<UserData> {
    return this.restApiService.getData<UserClaims[]>(`users/claims`).pipe(
      map(result => {
        const userId = find(result, { claim: 'DbUserId' });
        const email = find(result, { claim: 'UserEmail' });
        const name = find(result, { claim: 'name' });
        // todo - temporary
        const firstName = find(result, {
          claim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
        })?.value;
        const lastName = find(result, {
          claim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
        })?.value;

        const generalRole = find(result, {
          claim: 'GeneralRole',
        })?.value;

        const companyId = find(result, {
          claim: 'CompanyId',
        })?.value;

        const userType: UserType = (parseInt(
          find(result, {
            claim: 'UserTypeEnumValue',
          })?.value
        ) || 0) as UserType;

        const permissionLevel = result
          .filter(item => item.claim === 'PermissionLevel')
          .map(item => item.value as PermissionLevelType);

        return {
          email: email?.value,
          id: Number.parseInt(userId?.value),
          name: name.value,
          firstName,
          lastName,
          permissionLevel,
          generalRole,
          companyId: companyId ? parseInt(companyId, 10) : null,
          userType,
        };
      })
    );
  }

  getUserById(id: number): Observable<RestUserModel> {
    return this.restApiService.getData<RestUserModel>(`Users/${id}`);
  }

  setUserData(user: UpdateRestUserModel): Observable<RestUserModel> {
    this.cacheService.expireCachedInfo('getUserList');
    return this.restApiService.update<RestUserModel>(`Users/`, user);
  }

  removeUser(user: UserWithGeneralRole): Observable<boolean> {
    this.cacheService.expireCachedInfo('getUserList');
    return this.restApiService.delete<boolean>(`users/${user.id}`);
  }

  reactivateUser(user: UserWithGeneralRole): Observable<RestUserModel> {
    this.cacheService.expireCachedInfo('getUserList');
    return this.restApiService.create<RestUserModel>(`users/reactivate?id=${user.id}`, {});
  }

  inviteUser(user: InviteRestUserModel): Observable<RestUserModel> {
    this.cacheService.expireCachedInfo('getUserList');
    return this.restApiService.associate<RestUserModel>(`Users/Invitations`, user);
  }
}
