export enum PermissionLevelType {
  None = 'None',
  AllPermissions = 'AllPermissions',
  Inspections_SharedLibrary_List = 'Inspections_SharedLibrary_List',
  Inspections_SharedLibrary_Edit = 'Inspections_SharedLibrary_Edit',
  Inspections_List = 'Inspections_List',
  InspectionTemplates_List = 'InspectionTemplates_List',
  InspectionTemplates_Edit = 'InspectionTemplates_Edit',
  PropertyManager_Dashboard_Read = 'PropertyManager_Dashboard_Read',
  PropertyManager_PortfolioWideView = 'PropertyManager_PortfolioWideView',
  PropertyManager_UnitList_Read = 'PropertyManager_UnitList_Read',
  PropertyManager_UnitMix_Read = 'PropertyManager_UnitMix_Read',
  PropertyManager_ActiveTurnovers_Read = 'PropertyManager_ActiveTurnovers_Read',
  PropertyManager_Delinquencies_Read = 'PropertyManager_Delinquencies_Read',
  PropertyManager_Renewals_Read = 'PropertyManager_Renewals_Read',
  AssetManager_PropertyDashboard_Read = 'AssetManager_PropertyDashboard_Read',
  ConstructionManager_Dashboard_Read = 'ConstructionManager_Dashboard_Read',
  Administrator_Dashboard_Read = 'Administrator_Dashboard_Read',
  Turnovers_Kanban_Read = 'Turnovers_Kanban_Read',
  Turnovers_Archived_Read = 'Turnovers_Archived_Read',
  Delinquencies_Read = 'Delinquencies_Read',
  Renewals_Read = 'Renewals_Read',
  Management_Users = 'Management_Users',
  Management_Roles = 'Management_Roles',
  Management_Contractors_Read = 'Management_Contractors_Read',
  Management_PropertyManagers_Read = 'Management_PropertyManagers_Read',
  Management_ResponsibleParties_Read = 'Management_ResponsibleParties_Read',
  Management_UnitTargetRents_Read = 'Management_UnitTargetRents_Read',
  Management_Applicances_Read = 'Management_Applicances_Read',
  Management_KPI_Read = 'Management_KPI_Read',
  Management_Portfolio_Read = 'Management_Portfolio_Read',
  PropertyManager_UnitList_Edit = 'PropertyManager_UnitList_Edit',
  PropertyManager_UnitMix_Edit = 'PropertyManager_UnitMix_Edit',
  PropertyManager_ActiveTurnovers_Edit = 'PropertyManager_ActiveTurnovers_Edit',
  PropertyManager_Delinquencies_Edit = 'PropertyManager_Delinquencies_Edit',
  PropertyManager_Renewals_Edit = 'PropertyManager_Renewals_Edit',
  AssetManager_PropertyDashboard_Edit = 'AssetManager_PropertyDashboard_Edit',
  ConstructionManager_Dashboard_Edit = 'ConstructionManager_Dashboard_Edit',
  Administrator_Dashboard_Edit = 'Administrator_Dashboard_Edit',
  Turnovers_Kanban_Edit = 'Turnovers_Kanban_Edit',
  Turnovers_Archived_Edit = 'Turnovers_Archived_Edit',
  Delinquencies_Edit = 'Delinquencies_Edit',
  Renewals_Edit = 'Renewals_Edit',
  Management_Contractors_Edit = 'Management_Contractors_Edit',
  Management_PropertyManagers_Edit = 'Management_PropertyManagers_Edit',
  Management_ResponsibleParties_Edit = 'Management_ResponsibleParties_Edit',
  Management_UnitTargetRents_Edit = 'Management_UnitTargetRents_Edit',
  Management_Applicances_Edit = 'Management_Applicances_Edit',
  Management_KPI_Edit = 'Management_KPI_Edit',
  Management_Portfolio_Edit = 'Management_Portfolio_Edit',
  Companies_Read = 'Companies_Read',
  Companies_Edit = 'Companies_Edit',
  Tickets_Read = 'Tickets_Read',
  Tickets_Edit = 'Tickets_Edit',
  Turnovers_ReverseFlow = 'Turnovers_ReverseFlow',
  Turnovers_Delete = 'Turnovers_Delete',
  Tickets_EditAll = 'Tickets_EditAll',
  Management_TurnoverConfiguration_Read = 'Management_TurnoverConfiguration_Read',
  Management_TurnoverConfiguration_Edit = 'Management_TurnoverConfiguration_Edit',
  BulkScope = 'Bulk_Scope',
  Board_Use = 'Board_Use',
  Board_View = 'Board_View',
  Board_Edit = 'Board_Edit',
  Chat_Hooks = 'Chat_Hooks',
}
