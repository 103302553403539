export enum NotificationCategory {
  Turnover,
  TurnoverComment,
  Bid,
  BidAccepted,
  BidRejected,
  BidComment,
  Ticket,
  BidSentBack,
  TurnStepSchedule = 9,
}
