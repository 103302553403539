import { TimezoneHelper } from './timezone.helper';

export class TimezoneEntityHelperCommon {
  public static readonly fixTimezoneForModelToServer = <T>(data: T, timezone: number) => {
    if (!data || (typeof data !== 'object' && !Array.isArray(data))) {
      return data;
    }
    const dataWithoutTimezone = Array.isArray(data) ? ([...data] as T) : { ...data };
    for (const field in dataWithoutTimezone) {
      if (dataWithoutTimezone[field] instanceof Date || this.isStringLikeServerDate(dataWithoutTimezone[field])) {
        dataWithoutTimezone[field] = TimezoneHelper.replaceTimezoneToServer(
          dataWithoutTimezone[field] as Date,
          timezone
        ) as any;
      } else if (typeof dataWithoutTimezone[field] === 'object') {
        dataWithoutTimezone[field] = this.fixTimezoneForModelToServer(dataWithoutTimezone[field], timezone);
      }
    }
    return dataWithoutTimezone;
  };

  public static readonly fixTimezoneForModelToClient = <T>(data: T, timezone: number) => {
    if (!data) return data;
    for (const field in data) {
      if (data[field] instanceof Date || this.isStringLikeServerDate(data[field])) {
        data[field] = TimezoneHelper.replaceTimezoneToClient(data[field] as Date, timezone) as any;
      } else if (typeof data[field] === 'object') {
        this.fixTimezoneForModelToClient(data[field], timezone);
      }
    }
    return data;
  };

  private static isStringLikeServerDate(dateString: any) {
    return (
      typeof dateString === 'string' &&
      /^[0-9]{4}-[01][0-9]-[0-3][0-9]T[0-5][0-9]:[0-5][0-9]:[0-5][0-9](\.[0-9][0-9]?)?Z$/.test(dateString)
      //Valid examples of server date string:
      //2023-07-06T00:00:00Z
      //2023-07-06T09:37:48.2Z
      //2023-07-06T09:37:21.85Z
    );
  }
}
