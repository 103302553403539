import { RestTurnStepScheduleModel } from '@main-application/boards/interfaces/board';
import { RestTurnoverDataModel } from '@shared/interfaces/turnover.interface';
import { TimezoneEntityHelperCommon } from './timezone-entity-common.helper';
import { TimezoneHelper } from './timezone.helper';

export class TimezoneEntityHelper extends TimezoneEntityHelperCommon {
  public static readonly fixTimezoneForTurnStepScheduleModelToClient = (data: RestTurnStepScheduleModel) => {
    if (!data) return data;
    //Vanile Date
    data.dueDate = TimezoneHelper.addTimeZoneAndKeepOnlyDatePart(data.dueDate);
    data.turnoverDateAvailable = TimezoneHelper.addTimeZoneAndKeepOnlyDatePart(data.turnoverDateAvailable);
    return data;
  };
  public static readonly fixTimezoneForTurnStepScheduleModelToServer = (data: RestTurnStepScheduleModel) => {
    if (!data) return data;
    //Vanile Date
    const dataWithoutTimezone = { ...data };
    dataWithoutTimezone.dueDate = TimezoneHelper.removeTimeZone(dataWithoutTimezone.dueDate);
    dataWithoutTimezone.turnoverDateAvailable = TimezoneHelper.removeTimeZone(
      dataWithoutTimezone.turnoverDateAvailable
    );
    return dataWithoutTimezone;
  };
  public static readonly fixTimezoneForTurnoverDataModelToClient = (data: RestTurnoverDataModel, timezone: number) => {
    return this.fixTimezoneForTurnoverDataModel(
      data,
      timezone,
      TimezoneHelper.addTimeZoneAndKeepOnlyDatePart,
      TimezoneHelper.replaceTimezoneToClient,
      this.fixTimezoneForModelToClient
    );
  };
  public static readonly fixTimezoneForTurnoverDataModelToServer = (data: RestTurnoverDataModel, timezone: number) => {
    return this.fixTimezoneForTurnoverDataModel(
      { ...data },
      timezone,
      TimezoneHelper.removeTimeZone,
      TimezoneHelper.replaceTimezoneToServer,
      this.fixTimezoneForModelToServer
    );
  };
  public static readonly fixTimezoneForTurnoverDataModel = (
    data: RestTurnoverDataModel,
    timezone: number,
    fVanila: (date: Date | string) => Date,
    fFullDate: (date: Date | string, timezone: number) => Date,
    fModel: <T>(data: T, timezone: number) => T
  ) => {
    if (!data) return data;
    //Vanile Date
    data.dateMoveIn = fVanila(data.dateMoveIn);
    data.dateMoveOut = fVanila(data.dateMoveOut);
    data.pmsMoveOutDate = fVanila(data.pmsMoveOutDate);
    data.dateAvailable = fVanila(data.dateAvailable);
    data.leaseExecutionDate = fVanila(data.leaseExecutionDate);
    data.turnStepSchedules.forEach(e => (e.dueDate = fVanila(e.dueDate)));
    //Full Date
    data.dateCreated = fFullDate(data.dateCreated, timezone);
    data.dateUpdated = fFullDate(data.dateCreated, timezone);
    data.dateCompleted = fFullDate(data.dateCreated, timezone);
    data.dateCanceled = fFullDate(data.dateCreated, timezone);
    data.turnoverStartDate = fFullDate(data.dateCreated, timezone);
    data.turnoverEndDate = fFullDate(data.dateCreated, timezone);
    data.dateOccupiedInspected = fFullDate(data.dateCreated, timezone);
    data.dateVacantInspected = fFullDate(data.dateCreated, timezone);
    data.dateOffersSentOut = fFullDate(data.dateCreated, timezone);
    data.dateOfferExpires = fFullDate(data.dateCreated, timezone);
    data.firstMonthRent = fFullDate(data.dateCreated, timezone);
    //nested objects with Date
    data.createdBy = fModel(data.createdBy, timezone);
    data.portfolio = fModel(data.portfolio, timezone);
    data.property = fModel(data.property, timezone);
    data.unit = fModel(data.unit, timezone);
    data.workflow = data.workflow?.map(e => fModel(e, timezone));
    data.tickets = data.tickets?.map(e => fModel(e, timezone));
    data.bids = data.bids?.map(e => fModel(e, timezone));
    data.workflowStepComments = data.workflowStepComments?.map(e => fModel(e, timezone));

    return data;
  };
}
