import * as fromEnumerationReducer from './enumeration.reducer';
import * as fromFileUploadReducer from './file-upload.reducer';
import { reducer as RouteStateReducer, RouteState, routeStateFeatureKey } from './route.reducer';
import * as fromUserReducer from './user.reducer';
import * as fromWindowResizeReducer from './window-resize.reducer';

export const CORE_FEATURE_KEY = 'coreModule';

export interface CoreModuleState {
  [fromUserReducer.userFeatureKey]: fromUserReducer.UserState;
  [fromEnumerationReducer.enumerationFeatureKey]: fromEnumerationReducer.State;
  [fromFileUploadReducer.fileUploadFeatureKey]: fromFileUploadReducer.State;
  [fromWindowResizeReducer.windowResizeFeatureKey]: fromWindowResizeReducer.State;
  [routeStateFeatureKey]: RouteState;
}

export const reducers = {
  [fromUserReducer.userFeatureKey]: fromUserReducer.reducer,
  [fromEnumerationReducer.enumerationFeatureKey]: fromEnumerationReducer.reducer,
  [fromFileUploadReducer.fileUploadFeatureKey]: fromFileUploadReducer.reducer,
  [fromWindowResizeReducer.windowResizeFeatureKey]: fromWindowResizeReducer.reducer,
  [routeStateFeatureKey]: RouteStateReducer,
};
