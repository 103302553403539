import { Injectable } from '@angular/core';
import { RestapiServiceWithoutTimezone } from '@app/services/restapi.service';
import { first, map, mergeMap, Observable, ReplaySubject, tap } from 'rxjs';
import { TimezoneHelper } from '../timezone.helper';
import { RestTimezone } from './model/timezone';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private wasInitilized = false;
  private readonly currentTimezone = new ReplaySubject<number>(1);
  public readonly currentTimezone$ = this.currentTimezone.asObservable().pipe(first());
  private currentTimezoneValue = 0;
  constructor(private service: RestapiServiceWithoutTimezone) {}

  getPms(): Observable<any> {
    return this.service.getData<any>('system/pmsInfo');
  }

  getList(): Observable<RestTimezone[]> {
    return this.service.getData<RestTimezone[]>('system/getTimezones');
  }

  set(value: RestTimezone) {
    this.wasInitilized = true;
    this.currentTimezoneValue = value.utcOffsetMinutes;
    this.currentTimezone.next(this.currentTimezoneValue);
    return this.service.post<void>('system/setTimezone?timeZoneId=' + value.id);
  }

  get() {
    this.wasInitilized = true;
    return this.service.getData<RestTimezone>('system/getSelectedTimezone').pipe(
      tap(value => {
        this.currentTimezoneValue = value.utcOffsetMinutes;
        this.currentTimezone.next(this.currentTimezoneValue);
      })
    );
  }

  getCurrentDateOnly() {
    return new Date(this.getCurrentDate().toDateString());
  }

  getCurrentDate() {
    return TimezoneHelper.replaceTimezoneToClient(new Date(), this.currentTimezoneValue);
  }

  addTimezoneForEntity<T>(f: (entity: T, timezone: number) => T) {
    if (!this.wasInitilized) {
      this.get().subscribe();
    }
    return mergeMap((data: T) => this.currentTimezone$.pipe(map(timezone => f(data, timezone))));
  }
}
