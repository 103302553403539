import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppRoutes } from '@shared/constants/app-routes.const';
import { AuthService } from '@shared/services/auth.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, map, mergeMap, of, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private oidcService: OidcSecurityService,
    private location: Location
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const currentUrl = window.location.href;

    if (this.isAuthenticationInProgress() || this.authService.isAuthenticated()) {
      return of(true);
    }

    return this.oidcService.checkAuth().pipe(
      take(1),
      mergeMap(() =>
        this.oidcService.getAuthenticationResult().pipe(
          map(accessToken => {
            if (accessToken) {
              return true;
            }

            const loginUrlTree = this.router.parseUrl(AppRoutes.LOGIN);
            loginUrlTree.queryParams['returnUrl'] = currentUrl;
            return loginUrlTree;
          })
        )
      )
    );
  }

  private isAuthenticationInProgress() {
    return (
      this.location.path() === AppRoutes.LOGIN ||
      this.location.path() === AppRoutes.ACCEPT_TOKEN ||
      this.location.path() === AppRoutes.ROOT ||
      !!window.location.hash
    );
  }
}
