import { Injectable } from '@angular/core';
import { PortfolioKpiType, RestPortfolioKpi } from '@dashboards/models/portfolio-kpi';
import { sumBy } from 'lodash';
import { RestapiService } from '@app/services/restapi.service';
import { KpiWindowType } from '@shared/enums/kpi-window-type';

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  constructor(private restApiService: RestapiService) {}

  private filterKpiByType(kpis: RestPortfolioKpi[], kpiType: PortfolioKpiType): RestPortfolioKpi[] {
    return kpis.filter(k => k.kpiType === kpiType);
  }

  avg(kpis: RestPortfolioKpi[], kpiType: PortfolioKpiType, precision = 0): number | undefined {
    const filtered = this.filterKpiByType(kpis, kpiType);
    const avg = sumBy(filtered, k => k.value * k.unitsCount) / sumBy(filtered, k => k.unitsCount);
    if (isNaN(avg)) {
      return undefined;
    } else {
      return !precision ? Math.round(avg) : parseFloat(avg.toFixed(precision));
    }
  }

  sum(kpis: RestPortfolioKpi[], kpiType: PortfolioKpiType, precision = 0): number {
    const filtered = this.filterKpiByType(kpis, kpiType);
    return parseFloat(sumBy(filtered, k => k.value).toFixed(precision));
  }

  percent(kpis: RestPortfolioKpi[], kpiType: PortfolioKpiType): number | undefined {
    const filtered = this.filterKpiByType(kpis, kpiType);
    const value = sumBy(filtered, k => k.value) / sumBy(filtered, k => k.unitsCount);
    if (isNaN(value)) {
      return undefined;
    }
    return Math.round(value * 100);
  }

  fetch(portfolioId: number, dateOffset: KpiWindowType, kpiTypes: PortfolioKpiType[]) {
    return this.restApiService.getData<RestPortfolioKpi[]>(
      `portfolio/${portfolioId}/kpis?dateOffset=${dateOffset}&${kpiTypes.map(k => `kpiIds=${k}`).join('&')}`
    );
  }

  trend(portfolioId: number, kpiTypes: PortfolioKpiType[], trendDays: number[]) {
    return this.restApiService.getData<RestPortfolioKpi[]>(
      `portfolio/${portfolioId}/kpisTrend?kpi=[${kpiTypes.map(k => k).join(',')}]&trend_line=[${trendDays.join(',')}]`
    );
  }
}
