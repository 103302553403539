import { Injectable } from '@angular/core';
import { TenantService } from '@app/services/tenant.service';
import { UserService } from '@app/services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
  loadTenantData,
  loadUserById,
  loadUserByIdFailed,
  loadUserByIdSucceded,
  loadUserData,
  loadUserDataFailed,
  loadUserDataSucceded,
  loadUserTenantFailed,
  loadUserTenantSucceeded,
} from '../actions/user.actions';

@Injectable()
export class UserEffects {
  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserData),
      exhaustMap(action =>
        this.userService.getCashedUserData().pipe(
          map(userData => loadUserDataSucceded(userData)),
          catchError(() => {
            this.snackbarService.error(SnackbarErrorMessage.RetrievingUserData);
            return of(loadUserDataFailed());
          })
        )
      )
    )
  );

  loadTenantData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenantData),
      exhaustMap(action =>
        this.tenantService.getTenant().pipe(
          map(tenantData => loadUserTenantSucceeded(tenantData)),
          catchError(() => {
            this.snackbarService.error(SnackbarErrorMessage.RetrievingTenantData);
            return of(loadUserTenantFailed());
          })
        )
      )
    )
  );

  loadUserDataById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserById),
      exhaustMap(action =>
        this.userService.getUserById(action.userId).pipe(
          map(userData => loadUserByIdSucceded(userData)),
          catchError(() => {
            this.snackbarService.error(SnackbarErrorMessage.RetrievingUser);
            return of(loadUserByIdFailed());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private tenantService: TenantService
  ) {}
}
