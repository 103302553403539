export enum SnackbarErrorMessage {
  RetrievingCompanyList = 'Error retrieving company list',
  RetrievingCompanyData = 'Error retrieving company data',
  AddingNewVendor = 'Error adding new vendor',
  UpdatingVendor = 'Error updating vendor',
  DeletingVendor = 'Error deleting vendor',
  AddingEmployee = 'Error adding employee',
  DeletingEmployee = 'Error deleting employee',
  RetrievingVendors = 'Error retrieving vendors',
  AddingAddress = 'Error adding address',
  UpdatingAddress = 'Error updating address',

  T01RetrievingTasks = 'Error T01 retrieving tasks',
  T02RetrievingTasks = 'Error T02 retrieving tasks',
  RetrievingMyTasks = 'Error retrieving my tasks',
  T03RetrievingTasks = 'Error T03 retrieving tasks',
  T04RetrievingTasks = 'Error T04 retrieving tasks',
  LoadingTurnoverSummary = 'Error loading turnover summary',
  LoadingTurnoverDetails = 'Error loading turnover details',
  LoadingDelinquencySummary = 'Error loading delinquency summary',
  LoadingDelinquencyDetails = 'Error loading delinquency details',
  LoadingRenewalsSummary = 'Error loading renewals summary',
  LoadingUnitDetails = 'Error loading unit details',
  LoadingUnitDetailsByProperty = 'Error loading unit details by property',
  LoadingRenewalDetails = 'Error loading renewal details',
  LoadingProperties = 'Error loading properties',
  UL01LoadingUnitList = 'Error UL01 loading unit list',
  UL02LoadingUnitList = 'Error UL02 loading unit list',
  LoadingUnit = 'Error loading unit',
  UpdatingUnit = 'Error updating unit',
  LoadingAppliances = 'Error loading appliances',
  RetrievingRenealStrategy = 'Error retrieving renewal strategy',

  DL01RetrievingDelinquencyList = 'Error DL01 retrieving delinquency list',
  DL02RetrievingDelinquencyList = 'Error DL02 retrieving delinquency list',
  UpdatingDelinquency = 'Error updating delinquency',
  RetrievingDelinquencyHistory = 'Error retrieving delinquency history',

  RetrievingTurnoverConfigurationList = 'Error retrieving turnover configuration list',
  RetrievingTurnoverConfigurationElement = 'Error retrieving turnover configuration element',
  UpdatingTurnoverFieldConfiguration = 'Error updating turnover field configuration',

  ExecutingUpdate = 'Error executing update',
  RetrievingRenewalStrategies = 'Error retrieving renewal strategies',
  UpdatingRenewalStrategy = 'Error updating renewal strategy',

  RetrievingPropertyManagersMoveOut = "Error retrieving property manager's move out",
  RetrievingAssetManagersMoveOut = "Error retrieving asset manager's move out",
  RetrievingPropertyManagersArchivedMoveOut = "Error retrieving property manager's archived move out",
  RetrievingAssetManagersArchivedMoveOut = "Error retrieving asset manager's archived move out",

  RetrievingNotificationList = 'Error retrieving notification list',
  MarkingAllNotificationsAsRead = 'Error marking all notifications as read',
  MarkingNotificationAsRead = 'Error marking notification as read',
  DeletingNotification = 'Error deleting notification',

  EstablishingSignalRConnection = `Error establishing signalr connection: `,

  UnsupportedFileType = 'Unsupported file type',
  UploadingFile = 'Error uploading file',

  LoadingPortfoliosList = "Error loading portfolio's list",
  LoadingPortfolio = 'Error loading portfolio',
  LoadingPropertyDetails = 'Error loading property details',
  LoadingAllUserProperties = 'Error loading all user properties',
  LoadingUnassignedUserProperties = 'Error loading unassigned user properties',
  UpadtingPortfolio = 'Error updating portfolio',
  WhileAddingNewPropertyToPortfolio = 'Error while adding new property to portfolio',
  WhileDeletingPropertyFromPortfolio = 'Error while deleting property from portfolio',
  WhileCreatingNewPortfolio = 'Error while creating new portfolio',
  RetrievingPropertyManagers = 'Error retrieving property managers',
  RetrievingPropertyManagersForProperty = 'Error retrieving property managers for property',
  UpdatingPropertyDetails = 'Error updating property details',
  RemovingPortfolio = 'Error removing portfolio',
  RemovingManagerFromProperty = 'Error removing manager from property',
  AssigningManagerToProperty = 'Error assigning manager to property',
  RetrievingPropertyUnits = 'Error retrieving property units',
  RetrievingUnitsAttachments = 'Error retrieving units attachments',

  //Template
  LoadingTemplatesList = "Error loading template's list",
  RemovingTemplate = 'Error removing template',
  LoadingUnitTypes = "Error loading unit type's list",
  UpadtingTemplate = 'Error updating template',
  WhileCreatingNewTemplate = 'Error while creating new template',
  WhileDeletingPropertyFromTemplate = 'Error while deleting space from template',

  //SpaceTemplate
  LoadingSpaceTemplatesList = "Error loading space template's list",
  RemovingSpaceTemplate = 'Error removing space template',
  UpadtingSpaceTemplate = 'Error updating space template',
  LoadingCategories = "Error loading category's list",
  WhileCreatingNewSpaceTemplate = 'Error while creating new space template',

  //Inspections
  LoadingInspectionsList = "Error loading inspection's list",
  RemovingInspection = 'Error removing inspection',
  UpadtingInspection = 'Error updating inspection',
  WhileCreatingNewInspection = 'Error while creating new inspection',
  WhileAddingNewSharedSpace = 'Error while adding new shared space',

  // message
  AddingAttachment = 'Error adding attachment',
  AddingPhoto = 'Error adding photo',
  AddingFloorplan = 'Error adding floorplan',

  // message
  DeletingAttachment = 'Error deleting attachment',
  DeletingPhoto = 'Error deleting photo',
  DeletingFloorplan = 'Error deleting floorplan',

  DeletingFile = 'Error deleting file',

  RetrievingRenewalsList = 'Error retrieving renewals list',
  RetrievingRenewalData = 'Error retrieving renewal data',
  RetrievingRenewalStrategy = 'Error retrieving renewal strategy',
  UpdatingRenewal = 'Error updating renewal',
  RetrievingRenewalChangesHistory = 'Error retrieving renewal changes history',

  WhileLoadingResponsibleParties = 'Error while loading responsible parties',
  WhileCreatingAssignee = 'Error while creating assignee',
  WhileUpdatingAssignee = 'Error while updating assignee',

  RetrievingUserData = 'Error retrieving user data',
  RetrievingTenantData = 'Error retrieving tenant data',
  RetrievingUser = 'Error retrieving user',

  UnauthorizedAccess = 'Unauthorized access. Please contact with administrator.',

  RetrievingTicketList = 'Error retrieving ticket list',
  UpdatingTicket = 'Error updating ticket',

  CannotAcceptBid = 'You cannot accept until a bid has been received.',

  TurnoverAutomaticallyCreated = 'Remember to change this date in your PMS too!',
  AvailableCannotBeModifiedAfterApproval = 'Available cannot be modified after APPROVAL',

  RequiredFieldsCannotBeEmpty = 'Required fields cannot be empty.',

  CannotChangeUnitCondition = 'You cannot change unit condition after inspection has been completed. If you need to do this, please delete and recreate this turnover.',
  CompleteVacantOrOccupied = 'Complete Occupied inspection or Vacant inspection before moving to the next step.',
  CompleteOccupiedInspection = 'Complete Occupied inspection first',
  CompleteVacantInspection = 'Complete Vacant inspection first',

  InvalidFileExtension = `Invalid file extension. Accepted formats: `,

  UnitConditionForOccupiedInspection = 'Unit condition is required for Occupied inspection.',
  MoveOutTypeForOccupiedInspection = 'Move out type is required for Occupied inspection.',

  UnitConditionForVacantInspection = 'Unit condition is required for Vacant inspection.',
  MoveOutTypeForVacantInspection = 'Unit status is required for Vacant inspection.',
  VacantInspectionCannotOccur = 'Vacant Inspection cannot occur before Move Out Date of: ',

  AtLeastOnePhoto = 'At least one photo must be added before continuing.',

  BidMustBeAccepted = 'A bid must be accepted before Renovation can begin.',

  JobCannotStartUntil = `Job cannot start until tenant moves out on: `,

  CreatingBulkScope = 'Error creating bulk scope',
  RetrievingBulkScope = 'Error retrieving bulk scope',
  UpdatingBulkScope = 'Error updating bulk scope',
  RetrievingBulkScopeList = 'Error retrieving bulk scope list',

  CreatingTurnover = 'Error creating turnover',
  AssigningAssignee = 'Error assigning assignee',
  RetrievingTurnoverData = 'Error retrieving turnover data',
  UpdatingTurnoverData = 'Error updating turnover data',
  ErrorUpdatingTurnoverStepAssignee = 'Error updating turnover step assignee',
  ErrorRetrievingDefaultTurnoverAssignees = 'Error retrieving default turnover assignees',
  ChangingTurnoverStep = 'Error changing turnover step',
  RefreshingTimer = 'Error refreshing timer',
  AssigningAttachmentToEntity = 'Error assigning attachment to entity',
  UpdatingAttachment = 'Error updating attachment',
  RetrievingTurnoverTickets = 'Error retrieving turnover tickets',
  CreatingTicket = 'Error creating ticket',
  TicketCreated = 'Ticket created',
  TicketUpdated = 'Ticket updated',
  UpdatingTurnoverTicket = 'Error updating turnover ticket',
  RetrievingTurnoverTicket = 'Error retrieving turnover ticket',
  DeletingTicket = 'Error deleting ticket',
  AssigningAttachmentToTicket = 'Error assigning attachment to ticket',
  RetrievingTicket = 'Error retrieving ticket',
  RetrievingTicketAttachment = 'Error retrieving ticket attachments',
  RetrievingCompaniesList = 'Error retrieving companies list',
  RetrievingBidList = 'Error retrieving bid list',
  RetrievingVendorsList = 'Error retrieving vendors list',
  RetrievingBid = 'Error retrieving bid',
  AddingBid = 'Error adding bid',
  UpdatingBid = 'Error updating bid',
  AssigningAttachmentToBid = 'Error assigning attachment to bid',
  RetrievingApplianceData = 'Error retrieving appliance data',
  ReceivingTurnoverComments = 'Error receiving turnover comments',
  AddingComment = 'Error adding comment',
  UpdatingComment = 'Error updating comment',
  Deletingcomment = 'Error deleting comment',
  LoadingworkflowAssignment = 'Error loading workflow assignment',
  DeletingTurnover = 'Error deleting the turnover',
  DeletingFileFromRepository = 'Error deleting the file from repository',
  RetrievingTurnoverAttachmentList = 'Error retrieving turnover attachment list',
  RetrievingBidAttachmentList = 'Error retrieving bid attachment list',

  AllPunchTicketsMustBeDone = 'All punch tickets must be marked as done.',

  // TODO - it shouldn't be error message
  WebsiteAddressCopied = 'Website address copied (Ctrl + V to paste)',
  CopyingWebsiteAddress = 'Error copying website address',

  ErrorInvitingUser = 'Error inviting user',
  ErrorWrongEmail = 'Invalid email address. Please correct and try again.',
  ErrorUserAlreadyExist = 'The user {0} already exists',
  ErrorUpdatingUser = 'Error updating user',
  ErrorAssigningUserToRole = 'Error assigning user to role',
  ErrorAssigningUserToProperty = 'Error assigning user to property',
  ErrorRemovingPropertyAssignedUser = 'Error removing property assigned user',

  ErrorRetrievingSystemConfig = 'Error retrieving system configuration',
  ErrorRetrievingSlackChannels = 'Error retrieving slack channels',
  ErrorRetrievingTimerReport = 'Error retrieving timer report',
  SlackAdded = 'Slack successfully added',
  ErrorUpdatingSlackChannel = 'Error updating slack channel',
  ErrorUpdatingSystemConfig = 'Error updating system configuration',
  ErrorRetrievingElapsedDaysThresholds = 'Error retrieving elapsed days thresholds',
  ErrorUpdatingElapsedDaysThresholds = 'Error updating elapsed days thresholds',

  ErrorPortfolioKpi = 'Error getting portfolio KPIs',
  ErrorAddingFinish = 'Error adding new finish',
  ErrorUpdatingFinish = 'Error updating finish',
  ErrorDeletingFinish = 'Error deleting finish',
  ErrorLoadingFinish = 'Error loading finish',

  ErrorSurveysTemplates = 'Error getting survey templates',
  ErrorSavingSurveyTemplate = 'Error saving survey template',
  SuccessSavingSurveyTemplate = 'Survey template was saved',

  CommentLinkCopied = 'Comment link copied',

  ErrorSendingInspectionReport = 'Send inspection report failed',
  InspectionReportSentSuccessfully = 'Inspection report sent successfully',

  BuildiumSetupCompleted = 'Buildium setup is complete',
  BuildiumSetupIncompleted = 'Builidum setup has failed, please contact support',
  BoardEditPermissionRequired = 'Permission: board_edit required',
  TurnEditPermissionRequired = 'Permission: propertymanager_activeturnovers_edit required',
}
