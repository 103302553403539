export enum LocalStorageDataEnum {
  AVATAR_USER_CONFIG_LIST = 'avatar-user-config-list',
  BOARD_BUILDER = 'board-builder-configuration',
  KANBAN_ACTIVE_SECTIONS = 'kanban-active-sections',
  SETTINGS_TURNOVER_ELAPSED_DAYS_TOGGLE = 'settings-turnover-elapsed-days-toggle',
  SETTINGS_TURNOVER_STEP_ASSIGNEES_TOGGLE = 'settings-turnover-step-assignees-toggle',
  SETTINGS_CUSTOM_FIELDS_TOGGLE = 'settings-custom-fields-toggle',
  INSPECTION_LIST_SORT_ACTIVE = 'inspection-list-sort-active',
  INSPECTION_LIST_SORT_DIRECTION = 'inspection-list-sort-direction',
  INSPECTION_LIST_MODE = 'inspection-list-mode',
  CACHE_KEYS = 'cache-keys-{0}',
}
