import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutes } from '@shared/constants/app-routes.const';
import { SharedModule } from '@shared/shared.module';
import { AuthModule, LogLevel, OidcSecurityService } from 'angular-auth-oidc-client';
import { EnvironmentType } from 'src/environments/environment-config';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot({
      config: {
        authority: environment.azureAuthConfig.stsServer,
        redirectUrl: environment.azureAuthConfig.redirectUrl,
        clientId: environment.azureAuthConfig.clientID,
        responseType: 'id_token token',
        autoUserInfo: false,
        scope: environment.azureAuthConfig.b2cScopes,
        postLogoutRedirectUri: environment.azureAuthConfig.postLogoutRedirectUrl,
        silentRenew: true,
        useRefreshToken: true,
        forbiddenRoute: AppRoutes.NO_PERMISSION,
        unauthorizedRoute: AppRoutes.NO_PERMISSION,
        silentRenewUrl: environment.azureAuthConfig.redirectUrl,
        renewTimeBeforeTokenExpiresInSeconds: 43200,
        logLevel: environment.showAuthorizeLogs ? LogLevel.Debug : LogLevel.None,
        maxIdTokenIatOffsetAllowedInSeconds: 100,
        triggerAuthorizationResultEvent: true,
      },
    }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.type === EnvironmentType.Prod }),
    EffectsModule.forRoot([]),

    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    SharedModule,

    MatSnackBarModule,
    MatDialogModule,
  ],
  providers: [OidcSecurityService],
  bootstrap: [AppComponent],
})
export class AppModule {}
