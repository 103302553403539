import { UserType } from '@shared/enums/user-type';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';

export function getUserList(users: RestUserModel[]): IRadioButtonOption<number>[] {
  return users
    .filter(item => !!item.id)
    .map<IRadioButtonOption<number>>(item => {
      return {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      };
    });
}

export function getUserOrVendorList(users: RestUserModel[]): IRadioButtonOption<number>[] {
  return users
    .filter(item => !!item.id)
    .map<IRadioButtonOption<number>>(item => {
      return item.userGroupType === UserType.Contractor
        ? {
            value: item.id,
            label: item.companyName,
          }
        : {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
    });
}

export function getUserListDisplayName(users: RestUserModel[]): IRadioButtonOption<number>[] {
  return users
    .filter(item => !!item.id)
    .map<IRadioButtonOption<number>>(item => {
      return {
        value: item.id,
        label: item.displayName,
      };
    });
}

export function getInternalUserList(users: RestUserModel[]): IRadioButtonOption<number>[] {
  return users
    .filter(item => !!item.id && item.userGroupType === UserType.Employee)
    .map<IRadioButtonOption<number>>(item => {
      return {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      };
    });
}
