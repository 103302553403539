<div class="customized-snackbar-container position-relative">
  <button class="close-button" mat-icon-button (click)="dismiss()">
    <div class="display-flex justify-content-center align-items-center">
      <app-icon [iconHeight]="15" [iconWidth]="15" [iconColor]="EColorPalette.cWhite" [svg]="EIcon.CLOSE"></app-icon>
    </div>
  </button>
  <div>
    <span class="body-small" [innerHTML]="data"></span>
  </div>
</div>
