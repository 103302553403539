import { EnvironmentConfig, EnvironmentType } from './environment-config';

export const environment: EnvironmentConfig = {
  type: EnvironmentType.Demo,
  ver: '1.5.1',
  authBaseUrl: 'https://propupdemob2c.b2clogin.com/propupqab2c.onmicrosoft.com/oauth2/v2.0',
  apiUrl: 'https://api.propup-demo.com/api',
  openApiUrl: 'https://api.propup-demo.com/openApi',
  notificationsUrl: 'https://api.propup-demo.com/notifications',
  notificationsAllUrl: 'https://api.propup-demo.com/all_notifications',
  disableForm: true,
  showAuthorizeLogs: false,
  showBuildTime: false,
  azureAuthConfig: {
    b2cDirectory: 'propupdemob2c',
    stsBase: 'propupdemob2c.b2clogin.com',
    tenant: 'propupdemob2c.onmicrosoft.com',
    policyKey: 'B2C_1_SignUpSignIn',
    stsServer: 'https://propupdemob2c.b2clogin.com/propupdemob2c.onmicrosoft.com/B2C_1_SignUpSignIn/v2.0',
    clientID: '2a9fd775-ef7e-4c57-823b-00562893c6df',
    b2cScopes: 'openid offline_access 2a9fd775-ef7e-4c57-823b-00562893c6df',
    redirectUrl: 'https://propup-demo.com/accept-token',
    postLogoutRedirectUrl: 'https://propup-demo.com/login',
    forgotPasswordUrl: '',
  },
};
