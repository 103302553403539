import { Injectable } from '@angular/core';
import { RestapiService } from '@app/services/restapi.service';
import { EnumerationConfig } from '@shared/interfaces/enumeration-config';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { loadEnumerations, loadEnumerationsFailed, loadEnumerationsSuccess } from '../actions/enumerations.actions';

@Injectable()
export class EnumerationEffects {
  loadEnumerations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnumerations),
      exhaustMap(() =>
        this.restApiService.getData<EnumerationConfig>(`Enumerations`).pipe(
          map(result => loadEnumerationsSuccess({ enumerations: result })),
          catchError(err => of(loadEnumerationsFailed()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private restApiService: RestapiService, private store: Store<{}>) {}
}
