import { Injectable } from '@angular/core';
import { RestapiService } from '@app/services/restapi.service';
import { TimezoneEntityHelper } from '@main-application/management/components/system-configuration/components/timezone-entity.helper';
import { TimezoneHelper } from '@main-application/management/components/system-configuration/components/timezone.helper';
import { TurnoverStepTimer } from '@main-application/turnovers/interfaces/timers.interface';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import {
  PostTurnoverModel,
  RestTurnoverDataModel,
  RestTurnoverTaskModel,
  UpdateTypedAttachmentToEntityModel,
} from '@shared/interfaces/turnover.interface';
import { first } from 'lodash';
import { BehaviorSubject, Observable, finalize, of } from 'rxjs';
import { CacheService } from './cache.service';

class TurnoverPatchOperation<T> {
  op: 'add' | 'replace' | 'delete';
  path: KeyOfClassWithType<RestTurnoverDataModel, T>;
  value: T;
}

type KeyOfClassWithType<Class, AllowedType> = {
  [Property in keyof Class]: Class[Property] extends AllowedType ? Property : never;
}[keyof Class];

@Injectable({
  providedIn: 'root',
})
export class TurnoversService {
  kanbanListLoading = new BehaviorSubject<boolean>(false);
  kanbanListLoading$ = this.kanbanListLoading.asObservable();

  constructor(private restApiService: RestapiService, private cacheService: CacheService) {}

  create(postTurnoverModel: PostTurnoverModel): Observable<RestTurnoverDataModel> {
    this.cacheService.expireCachedInfo('getPortfolioTurnovers');
    return this.restApiService.create<RestTurnoverDataModel, PostTurnoverModel>(
      `Turnovers`,
      postTurnoverModel,
      null,
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient
    );
  }

  public static fixTimezoneForTurnoverTaskModel(data: RestTurnoverTaskModel[], timezone: number) {
    data.forEach(e => TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient(e.turnoverData, timezone));
    return data;
  }

  getTurnover(turnoverId: number): Observable<RestTurnoverDataModel> {
    return this.restApiService.getData<RestTurnoverDataModel>(
      `Turnovers/${turnoverId}`,
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient
    );
  }

  update(turnoverData: RestTurnoverDataModel): Observable<RestTurnoverDataModel> {
    this.cacheService.expireCachedInfo('getPortfolioTurnovers');
    return this.restApiService.update<RestTurnoverDataModel, RestTurnoverDataModel>(
      `Turnovers/${turnoverData.id}`,
      turnoverData,
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToServer,
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient
    );
  }

  patch<T>(id: number, patchData: TurnoverPatchOperation<T>[]): Observable<RestTurnoverDataModel> {
    this.cacheService.expireCachedInfo('getPortfolioTurnovers');

    return this.restApiService.customPatchData<RestTurnoverDataModel, TurnoverPatchOperation<T>[]>(
      `Turnovers/${id}`,
      patchData,
      data => {
        data.forEach(e => {
          if (['dateMoveIn', 'dateMoveOut', 'pmsMoveOutDate', 'dateAvailable', 'dateAvailable'].includes(e.path)) {
            e.value = TimezoneHelper.removeTimeZone(e.value as Date) as T;
          }
        });
        return data;
      },
      TimezoneEntityHelper.fixTimezoneForTurnoverDataModelToClient
    );
  }

  setAttachment(attachmentToEntity: UpdateTypedAttachmentToEntityModel): Observable<RestGenericTypedAttachment> {
    return this.restApiService.create<RestGenericTypedAttachment>(
      `Turnovers/${first(attachmentToEntity.turnoverIds)}/Attachments`,
      attachmentToEntity
    );
  }

  updateAttachment(attachmentToEntity: UpdateTypedAttachmentToEntityModel): Observable<boolean> {
    return this.restApiService.update<boolean>(
      `Turnovers/${first(attachmentToEntity.turnoverIds)}/Attachments`,
      attachmentToEntity
    );
  }

  getPropertyTurnovers(portfolioId: number): Observable<RestTurnoverTaskModel[]> {
    return this.restApiService.getData<RestTurnoverTaskModel[]>(
      `Turnovers/property/${portfolioId}`,
      TurnoversService.fixTimezoneForTurnoverTaskModel
    );
  }

  getCachedPortfolioTurnovers(portfolioId: number): Observable<RestTurnoverTaskModel[]> {
    return this.cacheService.getCachedInfo('getPortfolioTurnovers', portfolioId, (id: number) =>
      this.getPortfolioTurnovers(id)
    );
  }

  getPortfolioTurnovers(portfolioId: number): Observable<RestTurnoverTaskModel[]> {
    this.kanbanListLoading.next(true);
    return this.restApiService
      .getData<RestTurnoverTaskModel[]>(
        `Turnovers/portfolio/${portfolioId}`,
        TurnoversService.fixTimezoneForTurnoverTaskModel
      )
      .pipe(finalize(() => this.kanbanListLoading.next(false)));
  }

  getMyTasks(): Observable<RestTurnoverTaskModel[]> {
    return this.restApiService.getData<RestTurnoverTaskModel[]>(
      `Turnovers/mytasks`,
      TurnoversService.fixTimezoneForTurnoverTaskModel
    );
  }

  getAllTasks(portfolioId: number): Observable<RestTurnoverTaskModel[]> {
    return this.restApiService.getData<RestTurnoverTaskModel[]>(
      `Turnovers/alltasks?portfolioId=${portfolioId}`,
      TurnoversService.fixTimezoneForTurnoverTaskModel
    );
  }

  deleteTurnover(turnoverId: number): Observable<boolean> {
    this.cacheService.expireCachedInfo('getPortfolioTurnovers');
    return this.restApiService.delete(`Turnovers/${turnoverId}`);
  }

  deleteTurnoverAttachment(turnoverId: number, attachmentId: number): Observable<boolean> {
    return this.restApiService.delete(`Turnovers/${turnoverId}/Attachments/${attachmentId}`);
  }

  getTurnoversAttachments(turnoverId: number): Observable<RestGenericTypedAttachment[]> {
    return this.restApiService.getData<RestGenericTypedAttachment[]>(`Turnovers/${turnoverId}/Attachments`);
  }

  toggleAllSections(allPropertyIds: number[], active: boolean): Observable<number[]> {
    return of<number[]>(active ? allPropertyIds : []);
  }

  toggleSingleSection(activePropertyIds: number[], propertyId: number, active: boolean): Observable<number[]> {
    if (active) {
      return of<number[]>([...activePropertyIds, propertyId]);
    }
    return of<number[]>(activePropertyIds.filter(value => value !== propertyId));
  }

  getTurnoversTimers(turnoverId: number): Observable<TurnoverStepTimer[]> {
    return this.restApiService.getData<TurnoverStepTimer[]>(`Turnovers/${turnoverId}/timers`);
  }

  updateTurnoversTimers(turnoverId: number, timers: TurnoverStepTimer[]): Observable<TurnoverStepTimer[]> {
    this.cacheService.expireCachedInfo('getPortfolioTurnovers');
    return this.restApiService.create<TurnoverStepTimer[]>(`Turnovers/${turnoverId}/updateTimersData`, timers);
  }
}
