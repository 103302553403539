import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  deleteFile,
  deleteFileFailed,
  deleteFileSuccess,
  uploadFile,
  uploadFileFail,
  uploadFileSuccess,
} from '@shared/actions/file-upload.actions';
import { FileUploadsService } from '@app/services/file-uploads.service';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';

@Injectable()
export class FileUploadEffects {
  constructor(
    private actions$: Actions,
    private store: Store<{}>,
    private snackbarService: SnackbarService,
    private fileUploadsService: FileUploadsService
  ) {}

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadFile),
      mergeMap(action =>
        this.fileUploadsService.uploadFile(action.file).pipe(
          map(uploadedFile =>
            uploadFileSuccess({
              uploadedFileItem: {
                uploadedFile,
                attachmentSection: action.attachmentSection,
              },
            })
          ),
          catchError(err => {
            this.snackbarService.error(SnackbarErrorMessage.UploadingFile);
            return of(uploadFileFail());
          })
        )
      )
    )
  );

  deleteFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFile),
      mergeMap(action =>
        this.fileUploadsService.removeFile(action.fileId).pipe(
          map(uploadedFile => deleteFileSuccess()),
          catchError(err => {
            this.snackbarService.error(SnackbarErrorMessage.DeletingFile);
            return of(deleteFileFailed());
          })
        )
      )
    )
  );
}
