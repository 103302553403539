import { Injectable } from '@angular/core';
import { RestapiService } from '@app/services/restapi.service';
import { Observable } from 'rxjs';
import { TenantData } from '@shared/interfaces/tenant-data';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private restApiService: RestapiService) {}

  getTenant(): Observable<TenantData> {
    return this.restApiService.getData<TenantData>('system/tenantInfo');
  }
}
