import { createReducer, on } from '@ngrx/store';
import { setPreviousRoute } from '@shared/actions/route-state.action';

export const routeStateFeatureKey = 'routeState';

export interface RouteState {
  previousRoute?: string;
}

export const initialState: RouteState = {
  previousRoute: undefined,
};

export const reducer = createReducer(
  initialState,
  /**
   * set previous route
   */
  on(setPreviousRoute, (state, action) => ({
    ...state,
    previousRoute: action.previousRoute,
  }))
);
