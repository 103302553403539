export class TimezoneHelper {
  /**
   * Remove timezone & time part before sending value to server
   */
  static removeTimeZone(date: Date | string): Date {
    if (!date) {
      return null;
    }
    const convertedDate = new Date(date);
    return new Date(+convertedDate - convertedDate.getTimezoneOffset() * 60 * 1000);
  }
  /**
   * Add timezone for data which came from server, also remove timepart if it was
   */
  static addTimeZoneAndKeepOnlyDatePart(date: Date | string): Date {
    if (!date) {
      return null;
    }
    const convertedDate = new Date(date);
    const correctedDate = new Date(+convertedDate + convertedDate.getTimezoneOffset() * 60 * 1000);
    return new Date(correctedDate.toDateString());
  }
  /**
   * Change timezone for data for displaying
   */
  static replaceTimezoneToClient(date: Date | string, newTimezone: number = null, oldTimezone: number = null): Date {
    if (newTimezone == null) {
      newTimezone = 0;
    }
    if (oldTimezone == null) {
      oldTimezone = -new Date(date).getTimezoneOffset();
    }
    return TimezoneHelper.replaceTimezone(date, newTimezone, oldTimezone);
  }
  /**
   * Change timezone for data for displaying
   */
  static replaceTimezoneToServer(date: Date | string, oldTimezone: number = null, newTimezone: number = null): Date {
    if (oldTimezone == null) {
      oldTimezone = 0;
    }
    if (newTimezone == null) {
      newTimezone = -new Date(date).getTimezoneOffset();
    }
    return TimezoneHelper.replaceTimezone(date, newTimezone, oldTimezone);
  }
  /**
   * Change timezone for data for displaying
   */
  private static replaceTimezone(date: Date | string, newTimezone: number, oldTimezone: number): Date {
    if (!date) {
      return null;
    }
    const convertedDate = new Date(date);
    return new Date(+convertedDate + (newTimezone - oldTimezone) * 60 * 1000);
  }
}
