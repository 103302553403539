import { Injectable } from '@angular/core';
import { KpiService } from '@app/services/kpi.service';
import { RenewalsService } from '@app/services/renewals.service';
import { TimerService } from '@app/services/timer.service';
import { TurnoversService } from '@app/services/turnovers.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ActiveTurnoversDetailsCombined } from '@shared/interfaces/active-turnover-details';
import { DelinquencyDetailsCombined } from '@shared/interfaces/delinquency.interface';
import { PropertyKpi } from '@shared/interfaces/kpi';
import { MyProperties } from '@shared/interfaces/my-properties.interface';
import { RenewalDetailsCombined } from '@shared/interfaces/renewal.interface';
import { RestUnitSummaryModel } from '@shared/interfaces/rest-unit-summary-model.interface';
import { UnitDetailsByType } from '@shared/interfaces/unit-details-by-type';
import { UnitDetails } from '@shared/interfaces/unit-details.interface';
import { RestUnitModel } from '@shared/interfaces/unit.interface';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { RestapiService } from 'src/app/services/restapi.service';
import {
  getRenewalStrategyForUnit,
  getRenewalStrategyForUnitFailed,
  getRenewalStrategyForUnitSuccess,
  loadActiveTurnoversDetails,
  loadActiveTurnoversDetailsFailed,
  loadActiveTurnoversDetailsSucceded,
  loadActiveTurnoversSummary,
  loadActiveTurnoversSummaryFailed,
  loadActiveTurnoversSummarySucceded,
  loadDelinquencyByUnit,
  loadDelinquencyByUnitFailed,
  loadDelinquencyByUnitSucceded,
  loadDelinquencySummary,
  loadDelinquencySummaryFailed,
  loadDelinquencySummarySucceded,
  loadMyProperties,
  loadMyPropertiesFailed,
  loadMyPropertiesSuccess,
  loadMyTasks,
  loadMyTasksError,
  loadMyTasksSuccess,
  loadPropertyKpi,
  loadPropertyKpiFailed,
  loadPropertyKpiSucceded,
  loadRenewalsDetails,
  loadRenewalsDetailsFailed,
  loadRenewalsDetailsSuccess,
  loadRenewalsSummary,
  loadRenewalsSummaryFailed,
  loadRenewalsSummarySucceded,
  loadTurnoverKanbanPortfolio,
  loadTurnoverKanbanPortfolioError,
  loadTurnoverKanbanPortfolioSuccess,
  loadTurnoverKanbanProperty,
  loadTurnoverKanbanPropertyError,
  loadTurnoverKanbanPropertySuccess,
  loadUnit,
  loadUnitDetails,
  loadUnitDetailsByType,
  loadUnitDetailsByTypeFailed,
  loadUnitDetailsByTypeSuccess,
  loadUnitDetailsFailed,
  loadUnitDetailsSuccess,
  loadUnitFailed,
  loadUnitList,
  loadUnitListFailed,
  loadUnitListSuccess,
  loadUnitSuccess,
  loadUnitSummaryByProperty,
  loadUnitSummaryByPropertySucceded,
  loadUnitsWithActiveTurnovers,
  loadUnitsWithActiveTurnoversFailed,
  loadUnitsWithActiveTurnoversSuccess,
  updateUnit,
  updateUnitDoNotAutoCreateTurns,
  updateUnitDoNotAutoCreateTurnsFailed,
  updateUnitDoNotAutoCreateTurnsSuccess,
  updateUnitFailed,
  updateUnitSuccess,
} from '../actions/dashboard.actions';
import { ActiveTurnoversSummary } from '../models/active-turnover-summary';
import { DelinquencySummary } from '../models/delinquency-summary';
import { RenewalsSummary } from '../models/renewals-summary';
import { UnitsSummary } from '../models/units-summary';
import { selectCurrentDashboardViewTypeSimpleText } from '../selectors/dashboards.selectors';

@Injectable()
export class DashboardEffects {
  readonly routePath = 'properties/dashboards/';

  loadTurnoverKanbanProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTurnoverKanbanProperty),
      switchMap(action =>
        this.turnoversService.getPropertyTurnovers(action.propertyId).pipe(
          map(taskList => loadTurnoverKanbanPropertySuccess({ tasks: taskList || [] })),
          catchError(() => {
            this.snackbarService.error(SnackbarErrorMessage.T01RetrievingTasks);
            return of(loadTurnoverKanbanPropertyError());
          })
        )
      )
    )
  );

  loadTurnoverKanbanPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTurnoverKanbanPortfolio),
      switchMap(action =>
        this.timerService.reloadTimer().pipe(
          switchMap(() =>
            this.turnoversService.getPortfolioTurnovers(action.portfolioId).pipe(
              map(tasks => loadTurnoverKanbanPortfolioSuccess({ tasks: tasks || [] })),
              catchError(() => {
                this.snackbarService.error(SnackbarErrorMessage.T02RetrievingTasks);
                return of(loadTurnoverKanbanPortfolioError());
              })
            )
          )
        )
      )
    )
  );

  loadMyTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMyTasks),
      switchMap(action =>
        combineLatest([this.timerService.reloadTimer(), this.turnoversService.getAllTasks(action.portfolioId)]).pipe(
          map(([, tasks]) => loadMyTasksSuccess({ myTasks: tasks || [] })),
          catchError(() => {
            this.snackbarService.error(SnackbarErrorMessage.RetrievingMyTasks);
            return of(loadMyTasksError());
          })
        )
      )
    )
  );

  loadPropertyKpi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPropertyKpi),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<PropertyKpi[]>(`${this.routePath}PropertyKPI/${action.propertyId}/${dashboardEnumValue}`)
          .pipe(
            map(kpi => loadPropertyKpiSucceded({ kpi })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.T03RetrievingTasks);
              return of(loadPropertyKpiFailed());
            })
          )
      )
    )
  );

  loadUnitSummaryByProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUnitSummaryByProperty),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<UnitsSummary>(`${this.routePath}UnitSummaryByProperty/${action.propertyId}/${dashboardEnumValue}`)
          .pipe(
            map(unitSummary => loadUnitSummaryByPropertySucceded({ unitSummary })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.T04RetrievingTasks);

              return of(loadDelinquencySummaryFailed());
            })
          )
      )
    )
  );

  loadActiveTurnoversSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadActiveTurnoversSummary),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<ActiveTurnoversSummary>(
            `${this.routePath}ActiveTurnoversSummary/${action.propertyId}/${dashboardEnumValue}`
          )
          .pipe(
            map(result => loadActiveTurnoversSummarySucceded({ activeTurnoversSummary: result })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.LoadingTurnoverSummary);
              return of(loadActiveTurnoversSummaryFailed());
            })
          )
      )
    )
  );

  loadActiveTurnoversDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadActiveTurnoversDetails),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<ActiveTurnoversDetailsCombined>(
            `${this.routePath}ActiveTurnoversDetails/${action.propertyId}/${dashboardEnumValue}`
          )
          .pipe(
            map(result => loadActiveTurnoversDetailsSucceded({ activeTurnoversDetails: result })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.LoadingTurnoverDetails);
              return of(loadActiveTurnoversDetailsFailed());
            })
          )
      )
    )
  );

  loadDelinquencySummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDelinquencySummary),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<DelinquencySummary>(
            `${this.routePath}DelinquencySummary/${action.propertyId}/${dashboardEnumValue}/${action.delinquencyFilterType}`
          )
          .pipe(
            map(result => loadDelinquencySummarySucceded({ delinquencySummary: result })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.LoadingDelinquencySummary);
              return of(loadDelinquencySummaryFailed());
            })
          )
      )
    )
  );

  loadDelinquencyByUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDelinquencyByUnit),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<DelinquencyDetailsCombined>(
            `${this.routePath}DelinquencyDetails/${action.propertyId}/${dashboardEnumValue}/MoreThanTwentyFive`
          ) // TODO verify this option - MoreThanTwentyFive
          .pipe(
            map(result => loadDelinquencyByUnitSucceded({ delinquencyByUnit: result })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.LoadingDelinquencyDetails);
              return of(loadDelinquencyByUnitFailed());
            })
          )
      )
    )
  );

  loadRenewalsSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRenewalsSummary),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<RenewalsSummary>(`${this.routePath}RenewalsSummary/${action.propertyId}/${dashboardEnumValue}`)
          .pipe(
            map(renewalsSummary => loadRenewalsSummarySucceded({ renewalsSummary })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.LoadingRenewalsSummary);
              return of(loadRenewalsSummaryFailed());
            })
          )
      )
    )
  );

  loadUnitDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUnitDetails),
      switchMap(action =>
        this.restApiService
          .getData<UnitDetails[]>(`${this.routePath}UnitDetails/${action.propertyId}/${action.dashboardViewType}`)
          .pipe(
            map(result => loadUnitDetailsSuccess({ unitDetails: result })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.LoadingUnitDetails);
              return of(loadUnitDetailsFailed());
            })
          )
      )
    )
  );

  loadUnitDetailsByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUnitDetailsByType),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<UnitDetailsByType[]>(`${this.routePath}UnitDetailsByType/${action.propertyId}/${dashboardEnumValue}`)
          .pipe(
            map(result => loadUnitDetailsByTypeSuccess({ unitDetailsByType: result })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.LoadingUnitDetailsByProperty);
              return of(loadUnitDetailsByTypeFailed());
            })
          )
      )
    )
  );

  loadRenewalsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRenewalsDetails),
      withLatestFrom(this.store.select(selectCurrentDashboardViewTypeSimpleText)),
      switchMap(([action, dashboardEnumValue]) =>
        this.restApiService
          .getData<RenewalDetailsCombined>(
            `${this.routePath}RenewalsDetails/${action.propertyId}/${dashboardEnumValue}`
          )
          .pipe(
            map(renewalDetailsCombined => loadRenewalsDetailsSuccess({ renewalDetailsCombined })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.LoadingRenewalDetails);
              return of(loadRenewalsDetailsFailed());
            })
          )
      )
    )
  );

  loadMyProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMyProperties),
      switchMap(action =>
        this.restApiService.getData<MyProperties[]>(`${this.routePath}MyProperties/${action.portfolioId}`).pipe(
          map(result => loadMyPropertiesSuccess({ myProperties: result })),
          catchError(err => {
            this.snackbarService.error(SnackbarErrorMessage.LoadingProperties);
            return of(loadMyPropertiesFailed());
          })
        )
      )
    )
  );

  loadUnitsWithActiveTurnovers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUnitsWithActiveTurnovers),
      switchMap(action =>
        this.restApiService
          .getData<RestUnitSummaryModel[]>(`Units/property/${action.propertyId}/WithActiveTurnovers`)
          .pipe(
            map(unitList => loadUnitsWithActiveTurnoversSuccess({ unitList })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.UL01LoadingUnitList);
              return of(loadUnitsWithActiveTurnoversFailed());
            })
          )
      )
    )
  );

  loadUnitList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUnitList),
      switchMap(action =>
        this.restApiService
          .getData<RestUnitSummaryModel[]>(`Units/property/${action.propertyId}/WithNoActiveTurnovers`)
          .pipe(
            map(unitList => loadUnitListSuccess({ unitList })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.UL02LoadingUnitList);
              return of(loadUnitListFailed());
            })
          )
      )
    )
  );

  loadUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUnit),
      switchMap(action =>
        this.restApiService.getData<RestUnitModel>(`Units/${action.unitId}`).pipe(
          map(unit => loadUnitSuccess({ unit })),
          catchError(err => {
            this.snackbarService.error(SnackbarErrorMessage.LoadingUnit);
            return of(loadUnitFailed());
          })
        )
      )
    )
  );

  updateUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUnit),
      mergeMap(action =>
        this.restApiService.update<RestUnitModel>(`Units/${action.unit.id}`, action.unit).pipe(
          map(updatedUnit => updateUnitSuccess({ updatedUnit })),
          catchError(err => {
            this.snackbarService.error(SnackbarErrorMessage.UpdatingUnit);
            return of(updateUnitFailed());
          })
        )
      )
    )
  );

  updateUnitDoNotAutoCreateTurns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUnitDoNotAutoCreateTurns),
      mergeMap(action =>
        this.restApiService
          .post<RestUnitModel>(`Units/${action.unitId}/SetDoNotAutoCreateTurns`, {
            doNotAutoCreateTurns: action.doNotAutoCreateTurns,
          })
          .pipe(
            map(() => updateUnitDoNotAutoCreateTurnsSuccess({ doNotAutoCreateTurns: action.doNotAutoCreateTurns })),
            catchError(err => {
              this.snackbarService.error(SnackbarErrorMessage.UpdatingUnit);
              return of(updateUnitDoNotAutoCreateTurnsFailed());
            })
          )
      )
    )
  );

  getRenewalStrategyForUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRenewalStrategyForUnit),
      switchMap(action =>
        this.renewalsService.renewalTargetForUnitId(action.unitId).pipe(
          map(unitTypeRentTarget => getRenewalStrategyForUnitSuccess({ unitTypeRentTarget })),
          catchError(() => {
            this.snackbarService.error(SnackbarErrorMessage.RetrievingRenealStrategy);
            return of(getRenewalStrategyForUnitFailed());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private restApiService: RestapiService,
    private store: Store<{}>,
    private snackbarService: SnackbarService,
    private turnoversService: TurnoversService,
    private renewalsService: RenewalsService,
    private timerService: TimerService,
    private kpiService: KpiService
  ) {}
}
