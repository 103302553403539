export enum PortfolioKpiType {
  AvgTurnoverTime = 1,
  AvgOpenTurnoverNow = 93,
  VacantCount = 2,
  OccupancyPercent = 3,
  Expirations30 = 4,
  Expirations60 = 5,
  Expirations90 = 6,
  MoveOuts30 = 7,
  MoveOuts60 = 8,
  MoveOuts90 = 9,
  ApplicationsTotal30 = 10,
  Preleased30 = 11,
  EstOccupancyPercent30 = 12,
  EstOccupancyPercent60 = 13,
  EstOccupancyPercent90 = 14,
  EstOccupancyPercentSmart30 = 15,
  EstOccupancyPercentSmart60 = 16,
  EstOccupancyPercentSmart90 = 17,
  ExpirationsSmart30 = 18,
  ExpirationsSmart60 = 19,
  ExpirationsSmart90 = 20,
  MoveOutTurnovers = 21,
  Notices = 22,
  Bids = 23,
  MakeReady = 24,
  Reno = 25,
  Approval = 26,
  Punch = 27,
  TotalTurnovers = 28,
  Marketing = 29,
  Leasup = 30,
  MoveIn = 31,
  UnleasedUnits = 32,
  UnleasedUnitsNow = 94,
  NewApplications30 = 33,
  CancelledApplications30 = 34,
  PendingApplications30 = 35,
  DeniedApplications30 = 36,
  ApprovedApplications30 = 37,
  ConvertingApplications30 = 38,
  ConvertedApplications30 = 39,
  AvgMoveOutNow = 85,
  AvgMoveOut30 = 40,
  AvgBidNow = 86,
  AvgBid30 = 41,
  AvgMakeReadyNow = 87,
  AvgMakeReady30 = 42,
  AvgRenoNow = 88,
  AvgReno30 = 43,
  AvgLeasupNow = 89,
  AvgLeasup30 = 44,
  AvgApprovalPunchNow = 91,
  AvgApprovalPunch30 = 45,
  AvgMoveOut60 = 46,
  AvgBid60 = 47,
  AvgMakeReady60 = 48,
  AvgReno60 = 49,
  AvgLeasup60 = 50,
  AvgApprovalPunch60 = 51,
  AvgMoveOut90 = 52,
  AvgBid90 = 53,
  AvgMakeReady90 = 54,
  AvgReno90 = 55,
  AvgLeasup90 = 56,
  AvgApprovalPunch90 = 57,
  Preleased60 = 58,
  Preleased90 = 59,
  ApplicationsTotal60 = 60,
  NewApplications60 = 61,
  CancelledApplications60 = 62,
  PendingApplications60 = 63,
  DeniedApplications60 = 64,
  ApprovedApplications60 = 65,
  ConvertingApplications60 = 66,
  ConvertedApplications60 = 67,
  ApplicationsTotal90 = 68,
  NewApplications90 = 69,
  CancelledApplications90 = 70,
  PendingApplications90 = 71,
  DeniedApplications90 = 72,
  ApprovedApplications90 = 73,
  ConvertingApplications90 = 74,
  ConvertedApplications90 = 75,
  AverageTurnTime60 = 76,
  AverageTurnTime90 = 77,
  Leasing30 = 78,
  Leasing60 = 79,
  Leasing90 = 80,
  VacancyLossMedium = 81,
  VacancyLossMediumActiveTurns = 82,
  VacancyLossHigh = 83,
  VacancyLossHighActiveTurns = 84,
  VacancyLossNone = 131,
  VacancyLossNoneActiveTurns = 132,
  AvgPunch30 = 95,
  AvgPunch60 = 96,
  AvgPunch90 = 97,
  AvgApproval30 = 98,
  AvgApproval60 = 99,
  AvgApproval90 = 100,
  AvgMoveIn30 = 101,
  AvgMoveIn60 = 102,
  AvgMoveIn90 = 103,
  AvgPunchNow = 90,
  AvgApprovalNow = 91,
  AvgMoveInNow = 92,
}

export interface RestPortfolioKpi {
  calculationDate: string;
  dateUpdated: string;
  kpiType: PortfolioKpiType;
  portfolioId: number;
  propertyId: number;
  unitsCount: number;
  value: number;
}
