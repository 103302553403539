import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { tap } from 'rxjs';

const jwt = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private accessToken: string;

  constructor(private oidcService: OidcSecurityService) {}

  public isAuthenticated(): boolean {
    return !!this.accessToken;
  }

  public getAccessToken(): string {
    return this.accessToken;
  }

  public watchForAccessToken() {
    return this.oidcService.getAccessToken().pipe(tap(accessToken => (this.accessToken = accessToken)));
  }
}
