export enum EIcon {
  ANGLE_DOWN = 'angleDown',
  ANGLE_UP = 'angleUp',
  ANGLE_LEFT = 'angleLeft',
  ANGLE_RIGHT = 'angleRight',
  WARNING = 'warning',
  WARNING2 = 'warning2',
  HOME_HEART = 'homeHeart',
  ARROW_DOWN = 'arrowDown',
  ARROW_UP = 'arrowUp',
  ARROW_LEFT = 'arrowLeft',
  WAITING = 'waiting',
  FILE_PLUS = 'filePlus',
  HOME = 'home',
  SAD = 'sad',
  EXPIRATIONS = 'expirations',
  SIGN_OUT = 'signOut',
  FORWARD = 'forward',
  GREEN_FLAG = 'greenFlag',
  RED_FLAG = 'redFlag',
  YELLOW_FLAG = 'yellowFlag',
  REPEAT = 'repeat',
  DOLLAR = 'dollar',
  DONE = 'done',
  DONE_WB = 'doneWB',
  ERROR = 'error',
  QUESTION = 'question',
  SEARCH = 'search',
  TIME_SCHEDULE = 'timeSchedule',
  THUNDER_CIRCLE = 'thunderCircle',
  SHIELD_USER = 'shieldUser',
  PLUS = 'plus',
  USER = 'user',
  BOARD = 'board',
  DASHBOARD = 'dashboard',
  DASHBOARD2 = 'dashboard2',
  DASHBOARD3 = 'dashboard3',
  DASHBOARD4 = 'dashboard4',
  USER_GROUP = 'userGroup',
  CHAIR = 'chair',
  LIBRARY = 'library',
  VISIBLE = 'visible',
  FILE_DONE = 'fileDone',
  TIMER = 'timer',
  TOOLS = 'tools',
  ARCHIVE = 'archive',
  BINOCULAR = 'binocular',
  ARROW_FROM_LEFT = 'arrowFromLeft',
  ARROW_FROM_RIGHT = 'arrowFromRight',
  EDIT = 'edit',
  CLOSE = 'close',
  RADIO_SELECTED = 'radioSelected',
  RADIO_DEFAULT = 'radioDefault',
  BUILDING = 'building',
  IPHONE = 'iphone',
  MAIL = 'mail',
  MAIL2 = 'mail2',
  IMPORT = 'import',
  EXPORT = 'export',
  NOTIFICATION = 'notification',
  CHECK = 'check',
  HYPERLINK = 'hyperlink',
  ARROW_TO_BOTTOM = 'arrowToBottom',
  COMMENT = 'comment',
  COMMENT2 = 'comment2',
  CHECKBOX_SELECTED = 'checkboxSelected',
  CHECKBOX_DEFAULT = 'checkboxDefault',
  CROSS = 'cross',
  SAVE = 'save',
  TICK = 'tick',
  TICKET = 'ticket',
  TICKET2 = 'ticket2',
  MOVE_OUT = 'moveOut',
  HIDDEN = 'hidden',
  EXPAND_ARROWS = 'expandArrows',
  CHART_LINE = 'chartLine',
  NOTIFICATIONS = 'notifications',
  PENCIL = 'pencil',
  SETTINGS = 'settings',
  SETTINGS2 = 'settings2',
  TRASH = 'trash',
  EXPAND = 'expand',
  COLLAPSE = 'collapse',
  BLOCKS = 'blocks',
  MENU = 'menu',
  BULB = 'bulb',
  SUPPORT = 'support',
  BUG = 'bug',
  DOLLAR_CIRCLE = 'dollar_circle',
  LOGO = 'logo',
  EXCHANGE = 'exchange',
  SENDING = 'sending',
  SLEEP = 'sleep',
  REST = 'rest',
  HAMMER = 'hammer',
  CLIPBOARD = 'clipboard',
  BULLET_LIST = 'bulletList',
  NOTIFICATIONS_BIT = 'notifications_bit',
  PORTFOLIOS = 'portfolios',
  HALF_STAR = 'halfStar',
  DOWNLOAD = 'download',
  KEY = 'iconKey',
  TIME_SCHEDULE2 = 'iconTimeSchedule',
  REPEAT_ONE = 'iconRepeatOne',
  ALARM_CLOCK = 'iconAlarmClock',
  ESTIMATED_OCCUPANCY = 'iconEstimatedOccupancy',
  HOME_HEART2 = 'iconHomeHeart',
  REPEAT2 = 'iconRepeat',
  CLIPBOARD2 = 'iconClipboard',
  BID = 'iconBid',
  MAKE_READY = 'iconMakeReady',
  RENO = 'iconReno', //Brush
  DESIGN_PIN = 'designPin',
  MOVE_IN = 'iconMoveIn',
  NEW_TRASH = 'new_trash',
  FINGER = 'finger',
  WHITE_PLUS = 'white_plus',
  VACANCY_LOSS = 'vacancyLoss',
  UNIT = 'unit',
  PROPERTY = 'property',
  KEY2 = 'key',
  JOIN_MODE = 'iconJoinMode',
  SPLIT_MODE = 'iconSplitMode',
  SENTIMENT_SATISFIED = 'good',
  SENTIMENT_NEUTRAL = 'fair',
  SENTIMENT_DISSATISFIED = 'poor',
  CLIPBOARD_LIST = 'Clipboard_list',
  CHAT = 'Chat',
  FLAG = 'Flag',
  SKIPPED = 'skipped',
  CHECK_NO = 'Check_no',
  CHECK_YES = 'Check_yes',
  SHARE = 'share',
  FILTER = 'iconFilter',
  ICON_CALENDAR = 'iconCalendar',
  ICON_DOLLAR = 'iconDollar',
  ICON_JOIN = 'iconJoin',
  ICON_SPLIT = 'iconSplit',
  ICON_SEARCH = 'iconSearch',
  BB_TRASH = 'bb-trash',
  EMOJI = 'emojiDefault',
  EMOJI_HOVER = 'emojiHover',
  MOVE_OUT_POINT = 'moveOutPoint',
  MOVE_IN_POINT = 'moveInPoint',
  DELAY_ICON = 'delayIcon',
}
