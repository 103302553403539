import { PortfolioKpiType } from '@dashboards/models/portfolio-kpi';
import { VacancyLossAttrType } from '@main-application/turnovers/interfaces/vacancy-loss-attr';
import { VacancyLostThreshold } from '@main-application/turnovers/interfaces/vacancy-loss-threshold';
import { ActionReducer, createReducer, INIT, MetaReducer, on, UPDATE } from '@ngrx/store';
import { DashboardMode } from '@shared/enums/dashboard-mode';
import { ActiveTurnoversDetailsCombined } from '@shared/interfaces/active-turnover-details';
import { BreadcrumbItem } from '@shared/interfaces/breadcrumb-item';
import { DelinquencyDetailsCombined } from '@shared/interfaces/delinquency.interface';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';
import { PropertyKpi } from '@shared/interfaces/kpi';
import { MyProperties } from '@shared/interfaces/my-properties.interface';
import { RestUnitRenewalTargetModel } from '@shared/interfaces/renevals.interface';
import { RenewalDetailsCombined } from '@shared/interfaces/renewal.interface';
import { RestPortfolioModel } from '@shared/interfaces/rest-portfolio-model.interface';
import { RestUnitSummaryModel } from '@shared/interfaces/rest-unit-summary-model.interface';
import { RestTurnoverTaskModel } from '@shared/interfaces/turnover.interface';
import { UnitDetailsByType } from '@shared/interfaces/unit-details-by-type';
import { UnitDetails } from '@shared/interfaces/unit-details.interface';
import { RestUnitModel } from '@shared/interfaces/unit.interface';
import {
  clearActiveTurnoversSummary,
  clearDelinquencySummary,
  clearMyTasks,
  clearRenewalsSummary,
  clearTurnoverKanbanPortfolio,
  clearUnit,
  clearUnitSummaryByProperty,
  getRenewalStrategyForUnit,
  getRenewalStrategyForUnitFailed,
  getRenewalStrategyForUnitSuccess,
  joinProperties,
  kanbanListGenerateSuccess,
  loadActiveTurnoversDetails,
  loadActiveTurnoversDetailsFailed,
  loadActiveTurnoversDetailsSucceded,
  loadActiveTurnoversSummary,
  loadActiveTurnoversSummaryFailed,
  loadActiveTurnoversSummarySucceded,
  loadDelinquencyByUnit,
  loadDelinquencyByUnitFailed,
  loadDelinquencyByUnitSucceded,
  loadDelinquencySummary,
  loadDelinquencySummaryFailed,
  loadDelinquencySummarySucceded,
  loadMyProperties,
  loadMyPropertiesFailed,
  loadMyPropertiesSuccess,
  loadMyTasks,
  loadMyTasksError,
  loadMyTasksSuccess,
  loadPropertyKpi,
  loadPropertyKpiFailed,
  loadPropertyKpiSucceded,
  loadRenewalsDetails,
  loadRenewalsDetailsFailed,
  loadRenewalsDetailsSuccess,
  loadRenewalsSummary,
  loadRenewalsSummaryFailed,
  loadRenewalsSummarySucceded,
  loadTurnoverKanbanPortfolio,
  loadTurnoverKanbanPortfolioError,
  loadTurnoverKanbanPortfolioSuccess,
  loadTurnoverKanbanProperty,
  loadTurnoverKanbanPropertyError,
  loadTurnoverKanbanPropertySuccess,
  loadUnit,
  loadUnitDetails,
  loadUnitDetailsByType,
  loadUnitDetailsByTypeFailed,
  loadUnitDetailsByTypeSuccess,
  loadUnitDetailsFailed,
  loadUnitDetailsSuccess,
  loadUnitFailed,
  loadUnitList,
  loadUnitListFailed,
  loadUnitListSuccess,
  loadUnitSuccess,
  loadUnitSummaryByProperty,
  loadUnitSummaryByPropertyFailed,
  loadUnitSummaryByPropertySucceded,
  loadUnitsWithActiveTurnovers,
  loadUnitsWithActiveTurnoversFailed,
  loadUnitsWithActiveTurnoversSuccess,
  loadUserTurnovers,
  loadUserTurnoversError,
  loadUserTurnoversSuccess,
  myTasksHeaderData,
  resetCurrentPortfolio,
  resetCurrentProperty,
  resetTurnoverKanbanPortfolio,
  resetTurnoverKanbanProperty,
  resetUnitList,
  resetUnitsWithActiveTurnoversList,
  resetUserTurnoversError,
  selectedDashboardMode,
  selectedDashboardViewType,
  selectedPortfolioId,
  selectedPropertyId,
  setCurrentPortfolio,
  setCurrentProperty,
  setCustomBreadcrumbs,
  setVacancyLossAttrType,
  setVacancyLossThreshold,
  updateUnit,
  updateUnitDoNotAutoCreateTurns,
  updateUnitDoNotAutoCreateTurnsFailed,
  updateUnitDoNotAutoCreateTurnsSuccess,
  updateUnitFailed,
  updateUnitSuccess,
} from '../actions/dashboard.actions';
import { ActiveTurnoversSummary } from '../models/active-turnover-summary';
import { DelinquencySummary } from '../models/delinquency-summary';
import { RenewalsSummary } from '../models/renewals-summary';
import { UnitsSummary } from '../models/units-summary';

export const dashboardsFeatureKey = 'main-dashboard';

const UPDATE_STORAGE_ACTIONS: string[] = [
  selectedPortfolioId.type,
  joinProperties.type,
  setVacancyLossAttrType.type,
  setVacancyLossThreshold.type,
];

export interface DashboardsState {
  propertyUserTaskList: RestTurnoverTaskModel[];
  propertyUserTaskListLoading: boolean;
  portfolioUserTaskList: RestTurnoverTaskModel[];
  portfolioUserTaskListLoading: boolean;
  userTaskList: RestTurnoverTaskModel[];
  userTaskListLoading: boolean;
  myTasks: RestTurnoverTaskModel[];
  myTasksLoading: boolean;
  myTasksTicketsCount: number;
  myTasksTurnoversCount: number;
  kanbanListLoading: boolean;
  propertyKpi: PropertyKpi;
  propertyKpiLoading: boolean;
  kanbanListCount: number;
  unitsSummaryByProperty: UnitsSummary;
  unitsSummaryByPropertyLoading: boolean;
  activeTurnoversSummary: ActiveTurnoversSummary;
  activeTurnoversSummaryLoading: boolean;
  activeTurnoversDetails: ActiveTurnoversDetailsCombined;
  activeTurnoversDetailsLoading: boolean;
  delinquencyByUnit: DelinquencyDetailsCombined;
  delinquencyByUnitLoading: boolean;
  delinquencySummary: DelinquencySummary;
  delinquencySummaryLoading: boolean;
  renewalsSummary: RenewalsSummary;
  renewalsSummaryLoading: boolean;
  selectedPropertyId: number;
  selectedPortfolioId: number;
  unitDetailsLoading: boolean;
  unitDetails: UnitDetails[];
  unitDetailsByTypeLoading: boolean;
  unitDetailsByType: UnitDetailsByType[];
  renewalDetailsCombinedLoading: boolean;
  renewalDetailsCombined: RenewalDetailsCombined;
  selectedDashboardViewType: EnumerationItem;
  portfolioItemsLoading: boolean;
  portfolioItems: RestPortfolioModel[];
  myPropertiesLoading: boolean;
  myProperties: MyProperties[];
  dashboardMode: DashboardMode;
  breadcrumbList: BreadcrumbItem[];
  unitListWithActiveTurnover: RestUnitSummaryModel[];
  unitListWithActiveTurnoverLoading: boolean;
  unitList: RestUnitSummaryModel[];
  unitListLoading: boolean;
  unit: RestUnitModel;
  unitLoading: boolean;
  unitDoNotAutoCreateTurnsLoading: boolean;
  renewalStrategyForUnit: RestUnitRenewalTargetModel;
  renewalStrategyForUnitLoading: boolean;
  currentPropertyId: number;
  currentPortfolioId: number;
  joinProperties: boolean;
  selectedVacancyLossAttr: VacancyLossAttrType;
  selectedVacancyLossThreshold: VacancyLostThreshold;
  selectedVacancyLossThresholdKpiType: PortfolioKpiType;
  selectedVacancyLossThresholdKpiTypePerTurn: PortfolioKpiType;
}

export const initialState: DashboardsState = {
  activeTurnoversDetails: null,
  activeTurnoversDetailsLoading: false,
  activeTurnoversSummary: null,
  activeTurnoversSummaryLoading: false,
  breadcrumbList: [],
  currentPortfolioId: null,
  currentPropertyId: null,
  dashboardMode: null,
  delinquencyByUnit: null,
  delinquencyByUnitLoading: false,
  delinquencySummary: null,
  delinquencySummaryLoading: false,
  myProperties: [],
  myPropertiesLoading: false,
  portfolioItems: [],
  portfolioItemsLoading: false,
  portfolioUserTaskList: [],
  portfolioUserTaskListLoading: false,
  kanbanListLoading: false,
  kanbanListCount: null,
  propertyKpi: null,
  propertyKpiLoading: false,
  propertyUserTaskList: [],
  propertyUserTaskListLoading: false,
  userTaskList: [],
  userTaskListLoading: false,
  myTasks: [],
  myTasksLoading: false,
  myTasksTicketsCount: null,
  myTasksTurnoversCount: null,
  renewalDetailsCombined: null,
  renewalDetailsCombinedLoading: false,
  renewalStrategyForUnit: null,
  renewalStrategyForUnitLoading: false,
  renewalsSummary: null,
  renewalsSummaryLoading: false,
  selectedDashboardViewType: null,
  selectedPortfolioId: null,
  selectedPropertyId: undefined,
  unit: null,
  unitDetails: [],
  unitDetailsByType: [],
  unitDetailsByTypeLoading: false,
  unitDetailsLoading: false,
  unitList: [],
  unitListLoading: false,
  unitListWithActiveTurnover: [],
  unitListWithActiveTurnoverLoading: false,
  unitLoading: false,
  unitDoNotAutoCreateTurnsLoading: false,
  unitsSummaryByProperty: null,
  unitsSummaryByPropertyLoading: false,
  joinProperties: false,
  selectedVacancyLossAttr: VacancyLossAttrType.Gross,
  selectedVacancyLossThreshold: VacancyLostThreshold.None,
  selectedVacancyLossThresholdKpiType: PortfolioKpiType.VacancyLossNone,
  selectedVacancyLossThresholdKpiTypePerTurn: PortfolioKpiType.VacancyLossNoneActiveTurns,
};

export const hydrationMetaReducer = (reducer: ActionReducer<DashboardsState>): ActionReducer<DashboardsState> => {
  return (state, action) => {
    if (action.type === INIT || action.type === UPDATE) {
      const storageValue = localStorage.getItem(dashboardsFeatureKey);
      if (storageValue) {
        try {
          const parsedValue = JSON.parse(storageValue);
          return { ...initialState, ...parsedValue };
        } catch {
          localStorage.removeItem(dashboardsFeatureKey);
        }
      }
    }
    const nextState = reducer(state, action);
    if (UPDATE_STORAGE_ACTIONS.includes(action.type)) {
      localStorage.setItem(
        dashboardsFeatureKey,
        JSON.stringify({
          selectedPortfolioId: nextState.selectedPortfolioId,
          joinProperties: nextState.joinProperties,
          selectedVacancyLossAttr: nextState.selectedVacancyLossAttr,
          selectedVacancyLossThreshold: nextState.selectedVacancyLossThreshold,
          selectedVacancyLossThresholdKpiType: nextState.selectedVacancyLossThresholdKpiType,
          selectedVacancyLossThresholdKpiTypePerTurn: nextState.selectedVacancyLossThresholdKpiTypePerTurn,
        })
      );
    }
    return nextState;
  };
};

export const metaDashboardReducers: Array<MetaReducer<any, any>> = [hydrationMetaReducer];

export const reducer = createReducer(
  initialState,
  on(loadTurnoverKanbanProperty, (state, action) => ({
    ...state,
    propertyUserTaskListLoading: true,
  })),
  on(loadTurnoverKanbanPropertySuccess, (state, action) => ({
    ...state,
    propertyUserTaskList: action.tasks,
    propertyUserTaskListLoading: false,
  })),
  on(loadTurnoverKanbanPropertyError, (state, action) => ({
    ...state,
    propertyUserTaskList: [],
    propertyUserTaskListLoading: false,
  })),
  on(resetTurnoverKanbanProperty, (state, action) => ({
    ...state,
    propertyUserTaskList: [],
  })),
  on(loadUserTurnovers, (state, action) => ({
    ...state,
    userTaskListLoading: true,
  })),
  on(loadUserTurnoversSuccess, (state, action) => ({
    ...state,
    userTaskList: action.tasks,
    userTaskListLoading: false,
  })),
  on(loadUserTurnoversError, (state, action) => ({
    ...state,
    userTaskList: [],
    userTaskListLoading: false,
  })),
  on(resetUserTurnoversError, (state, action) => ({
    ...state,
    userTaskList: [],
  })),
  on(loadMyTasks, (state, action) => ({
    ...state,
    myTasksLoading: true,
  })),
  on(loadMyTasksSuccess, (state, action) => ({
    ...state,
    myTasks: action.myTasks,
    myTasksLoading: false,
  })),
  on(loadMyTasksError, (state, action) => ({
    ...state,
    myTasks: [],
    myTasksLoading: false,
  })),
  on(clearMyTasks, (state, action) => ({
    ...state,
    myTasks: [],
  })),
  on(myTasksHeaderData, (state, action) => ({
    ...state,
    myTasksTicketsCount: action.myTasksTicketsCount,
    myTasksTurnoversCount: action.myTasksTurnoversCount,
  })),
  on(clearTurnoverKanbanPortfolio, (state, action) => ({
    ...state,
    portfolioUserTaskList: null,
  })),
  on(loadTurnoverKanbanPortfolio, (state, action) => ({
    ...state,
    portfolioUserTaskListLoading: true,
    kanbanListLoading: true,
  })),
  on(loadTurnoverKanbanPortfolioSuccess, (state, action) => ({
    ...state,
    portfolioUserTaskList: action.tasks,
    portfolioUserTaskListLoading: false,
  })),
  on(loadTurnoverKanbanPortfolioError, (state, action) => ({
    ...state,
    portfolioUserTaskList: [],
    portfolioUserTaskListLoading: false,
    kanbanListLoading: false,
    kanbanListCount: 0,
  })),
  on(resetTurnoverKanbanPortfolio, (state, action) => ({
    ...state,
    portfolioUserTaskList: [],
  })),
  on(kanbanListGenerateSuccess, (state, action) => ({
    ...state,
    kanbanListLoading: false,
    kanbanListCount: action.kanbanListCount,
  })),

  on(loadPropertyKpi, (state, action) => ({ ...state, propertyKpiLoading: true })),
  on(loadPropertyKpiSucceded, (state, action) => ({ ...state, propertyKpiLoading: false, propertyKpi: action.kpi })),
  on(loadPropertyKpiFailed, (state, action) => ({ ...state, propertyKpiLoading: false, propertyKpi: null })),
  on(loadUnitSummaryByProperty, (state, action) => ({ ...state, unitsSummaryByPropertyLoading: true })),
  on(loadUnitSummaryByPropertySucceded, (state, action) => ({
    ...state,
    unitsSummaryByPropertyLoading: false,
    unitsSummaryByProperty: action.unitSummary,
  })),
  on(loadUnitSummaryByPropertyFailed, (state, action) => ({
    ...state,
    unitsSummaryByPropertyLoading: false,
    unitsSummaryByProperty: null,
  })),
  on(clearUnitSummaryByProperty, (state, action) => ({
    ...state,
    unitsSummaryByProperty: null,
  })),
  on(loadActiveTurnoversSummary, (state, action) => ({ ...state, activeTurnoversSummaryLoading: true })),
  on(loadActiveTurnoversSummarySucceded, (state, action) => ({
    ...state,
    activeTurnoversSummaryLoading: false,
    activeTurnoversSummary: action.activeTurnoversSummary,
  })),
  on(loadActiveTurnoversSummaryFailed, (state, action) => ({
    ...state,
    activeTurnoversSummaryLoading: false,
    activeTurnoversSummary: null,
  })),
  on(clearActiveTurnoversSummary, (state, action) => ({
    ...state,
    activeTurnoversSummary: null,
  })),
  on(loadActiveTurnoversDetails, (state, action) => ({ ...state, activeTurnoversDetailsLoading: true })),
  on(loadActiveTurnoversDetailsSucceded, (state, action) => ({
    ...state,
    activeTurnoversDetailsLoading: false,
    activeTurnoversDetails: action.activeTurnoversDetails,
  })),
  on(loadActiveTurnoversDetailsFailed, (state, action) => ({
    ...state,
    activeTurnoversDetailsLoading: false,
    activeTurnoversDetails: null,
  })),
  on(loadDelinquencySummary, (state, action) => ({ ...state, delinquencySummaryLoading: true })),
  on(loadDelinquencySummarySucceded, (state, action) => ({
    ...state,
    delinquencySummary: action.delinquencySummary,
    delinquencySummaryLoading: false,
  })),
  on(loadDelinquencySummaryFailed, (state, action) => ({
    ...state,
    delinquencySummaryLoading: false,
    delinquencySummary: null,
  })),
  on(clearDelinquencySummary, (state, action) => ({ ...state, delinquencySummary: null })),

  on(loadDelinquencyByUnit, (state, action) => ({ ...state, delinquencyByUnitLoading: true })),
  on(loadDelinquencyByUnitSucceded, (state, action) => ({
    ...state,
    delinquencyByUnitLoading: false,
    delinquencyByUnit: action.delinquencyByUnit,
  })),
  on(loadDelinquencyByUnitFailed, (state, action) => ({
    ...state,
    delinquencyByUnitLoading: false,
    delinquencyByUnit: null,
  })),
  on(loadRenewalsSummary, (state, action) => ({ ...state, renewalsSummaryLoading: true })),
  on(loadRenewalsSummarySucceded, (state, action) => ({
    ...state,
    renewalsSummaryLoading: false,
    renewalsSummary: action.renewalsSummary,
  })),
  on(loadRenewalsSummaryFailed, (state, action) => ({
    ...state,
    renewalsSummaryLoading: false,
    renewalsSummary: null,
  })),
  on(clearRenewalsSummary, (state, action) => ({
    ...state,
    renewalsSummary: null,
  })),

  on(selectedPropertyId, (state, action) => ({ ...state, selectedPropertyId: action.propertyId })),

  on(loadUnitDetails, (state, action) => ({ ...state, unitDetailsLoading: true })),
  on(loadUnitDetailsSuccess, (state, action) => ({
    ...state,
    unitDetailsLoading: false,
    unitDetails: action.unitDetails,
  })),
  on(loadUnitDetailsFailed, (state, action) => ({ ...state, unitDetailsLoading: false, unitDetails: [] })),
  on(loadUnitDetailsByType, (state, action) => ({ ...state, unitDetailsByTypeLoading: true })),
  on(loadUnitDetailsByTypeSuccess, (state, action) => ({
    ...state,
    unitDetailsByTypeLoading: false,
    unitDetailsByType: action.unitDetailsByType,
  })),
  on(loadUnitDetailsByTypeFailed, (state, action) => ({
    ...state,
    unitDetailsByTypeLoading: false,
    unitDetailsByType: [],
  })),
  on(loadRenewalsDetails, (state, action) => ({ ...state, renewalDetailsCombinedLoading: true })),
  on(loadRenewalsDetailsSuccess, (state, action) => ({
    ...state,
    renewalDetailsCombinedLoading: false,
    renewalDetailsCombined: action.renewalDetailsCombined,
  })),
  on(loadRenewalsDetailsFailed, (state, action) => ({
    ...state,
    renewalDetailsCombinedLoading: false,
    renewalsDetails: {
      renewalDetailsCombined: [],
      renewalKPI: null,
    },
  })),
  on(selectedDashboardViewType, (state, action) => ({
    ...state,
    selectedDashboardViewType: action.dashboardViewType,
  })),
  on(selectedPortfolioId, (state, action) => ({ ...state, selectedPortfolioId: action.portfolioId })),
  on(loadMyProperties, (state, action) => ({ ...state, myPropertiesLoading: true })),
  on(loadMyPropertiesSuccess, (state, action) => ({
    ...state,
    myPropertiesLoading: false,
    myProperties: action.myProperties,
  })),
  on(loadMyPropertiesFailed, (state, action) => ({
    ...state,
    myPropertiesLoading: false,
    myProperties: [],
  })),
  on(selectedDashboardMode, (state, action) => ({ ...state, dashboardMode: action.dashboardMode })),
  on(setCustomBreadcrumbs, (state, action) => ({ ...state, breadcrumbList: action.breadcrumbList })),

  /**
   * load Units With Active Turnovers
   */
  on(loadUnitsWithActiveTurnovers, (state, action) => ({ ...state, unitListWithActiveTurnoverLoading: true })),
  on(loadUnitsWithActiveTurnoversSuccess, (state, action) => ({
    ...state,
    unitListWithActiveTurnover: action.unitList,
    unitListWithActiveTurnoverLoading: false,
  })),
  on(loadUnitsWithActiveTurnoversFailed, (state, action) => ({
    ...state,
    unitListWithActiveTurnover: [],
    unitListWithActiveTurnoverLoading: false,
  })),
  on(resetUnitsWithActiveTurnoversList, (state, action) => ({
    ...state,
    unitListWithActiveTurnover: [],
  })),
  /**
   * load Units With Active Turnovers
   */
  on(loadUnitList, (state, action) => ({ ...state, unitListLoading: true })),
  on(loadUnitListSuccess, (state, action) => ({
    ...state,
    unitList: action.unitList,
    unitListLoading: false,
  })),
  on(loadUnitListFailed, (state, action) => ({
    ...state,
    unitList: [],
    unitListLoading: false,
  })),
  on(resetUnitList, (state, action) => ({
    ...state,
    unitList: [],
  })),

  /**
   * load Unit
   */
  on(loadUnit, (state, action) => ({ ...state, unitLoading: true })),
  on(loadUnitSuccess, (state, action) => ({
    ...state,
    unit: action.unit,
    unitLoading: false,
  })),
  on(loadUnitFailed, (state, action) => ({
    ...state,
    unit: null,
    unitLoading: false,
  })),
  on(clearUnit, (state, action) => ({
    ...state,
    unit: null,
  })),

  /**
   * update Unit
   */
  on(updateUnit, (state, action) => ({ ...state, unitLoading: true })),
  on(updateUnitSuccess, (state, action) => ({
    ...state,
    unit: action.updatedUnit,
    unitLoading: false,
  })),
  on(updateUnitFailed, (state, action) => ({
    ...state,
    unit: null,
    unitLoading: false,
  })),

  /**
   * update Unit DoNotAutoCreateTurns
   */
  on(updateUnitDoNotAutoCreateTurns, (state, action) => ({ ...state, unitDoNotAutoCreateTurnsLoading: true })),
  on(updateUnitDoNotAutoCreateTurnsSuccess, (state, action) => ({
    ...state,
    unit: { ...state.unit, doNotAutoCreateTurns: action.doNotAutoCreateTurns },
    unitDoNotAutoCreateTurnsLoading: false,
  })),
  on(updateUnitDoNotAutoCreateTurnsFailed, (state, action) => ({
    ...state,
    unit: { ...state.unit },
    unitDoNotAutoCreateTurnsLoading: false,
  })),

  /**
   * get renewal Strategy For Unit
   */
  on(getRenewalStrategyForUnit, (state, action) => ({ ...state, renewalStrategyForUnitLoading: true })),
  on(getRenewalStrategyForUnitSuccess, (state, action) => ({
    ...state,
    renewalStrategyForUnit: action.unitTypeRentTarget,
    renewalStrategyForUnitLoading: false,
  })),
  on(getRenewalStrategyForUnitFailed, (state, action) => ({
    ...state,
    renewalStrategyForUnit: null,
    renewalStrategyForUnitLoading: false,
  })),
  /**
   * current portfolio
   */
  on(setCurrentPortfolio, (state, action) => ({
    ...state,
    currentPortfolioId: action.portfolioId,
  })),
  on(resetCurrentPortfolio, (state, action) => ({
    ...state,
    currentPortfolioId: null,
  })),

  /**
   * current property
   */
  on(setCurrentProperty, (state, action) => ({
    ...state,
    currentPropertyId: action.propertyId,
  })),
  on(resetCurrentProperty, (state, action) => ({
    ...state,
    currentPropertyId: null,
  })),
  on(joinProperties, (state, action) => ({
    ...state,
    joinProperties: action.state,
  })),
  on(setVacancyLossAttrType, (state, action) => ({
    ...state,
    selectedVacancyLossAttr: action.attrType,
  })),
  on(setVacancyLossThreshold, (state, action) => {
    let selectedVacancyLossThresholdKpiType, selectedVacancyLossThresholdKpiTypePerTurn: PortfolioKpiType;
    switch (action.thresholdType) {
      case VacancyLostThreshold.None:
        selectedVacancyLossThresholdKpiType = PortfolioKpiType.VacancyLossNone;
        selectedVacancyLossThresholdKpiTypePerTurn = PortfolioKpiType.VacancyLossNoneActiveTurns;
        break;
      case VacancyLostThreshold.Medium:
        selectedVacancyLossThresholdKpiType = PortfolioKpiType.VacancyLossMedium;
        selectedVacancyLossThresholdKpiTypePerTurn = PortfolioKpiType.VacancyLossMediumActiveTurns;
        break;
      case VacancyLostThreshold.High:
        selectedVacancyLossThresholdKpiType = PortfolioKpiType.VacancyLossHigh;
        selectedVacancyLossThresholdKpiTypePerTurn = PortfolioKpiType.VacancyLossHighActiveTurns;
        break;
    }
    return {
      ...state,
      selectedVacancyLossThreshold: action.thresholdType,
      selectedVacancyLossThresholdKpiType,
      selectedVacancyLossThresholdKpiTypePerTurn,
    };
  })
);
