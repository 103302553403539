import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserEffects } from './effects/user.effects';
import { TokenInterceptor } from './interceptors/token.interceptor';

import { DashboardEffects } from '@main-application/dashboards/effects/dashboard.effects';
import { WindowResizeEffects } from '@shared/effects/window-resize.effects';
import { EnumerationEffects } from './effects/enumeration.effects';
import { FileUploadEffects } from './effects/file-upload.effects';
import { CORE_FEATURE_KEY, reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    EffectsModule.forFeature([
      UserEffects,
      EnumerationEffects,
      DashboardEffects,
      FileUploadEffects,
      WindowResizeEffects,
    ]),
    StoreModule.forFeature(CORE_FEATURE_KEY, reducers),
  ],
  declarations: [],
  providers: [
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
