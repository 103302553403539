import { createAction, props } from '@ngrx/store';
import { RestPropertyModel } from '@shared/interfaces/rest-portfolio-model.interface';
import { UserData } from '@shared/interfaces/user-data';
import { TenantData } from '@shared/interfaces/tenant-data';

export const loadTenantData = createAction('[App][Core] Load tenant data');
export const loadUserTenantSucceeded = createAction('[App][Core] Load tenant data succeeded', props<TenantData>());
export const loadUserTenantFailed = createAction('[App][Core] Load tenant data failed');

export const loadUserData = createAction('[App][Core] Load user data');
export const loadUserDataSucceded = createAction('[App][Core] Load user data succeeded', props<UserData>());
export const loadUserDataFailed = createAction('[App][Core] Load user data failed');

export const loadUserById = createAction('[App][Core] Load user by id', props<{ userId: number }>());
export const loadUserByIdSucceded = createAction('[App][Core] Load user by id succeded', props<UserData>());
export const loadUserByIdFailed = createAction('[App][Core] Load user by id failed');

export const setUserGeneralRole = createAction('[App][Core] User general role update', props<{ roleName: string }>());
export const setAuthenticated = createAction('[App][Core] set authenticated', props<{ isAuthenticated: boolean }>());

export const loadAllUserProperties = createAction('[App][Core] Load all user properties');
export const loadAllUserPropertiesSucceeded = createAction(
  '[App][Core] Load all user properties succeeded',
  props<{ properties: RestPropertyModel[] }>()
);
export const loadAllUserPropertiesFailed = createAction('[App][Core] Load all user properties failed');
