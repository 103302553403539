import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimezoneService } from '@main-application/management/components/system-configuration/components/date-time-configuration/timezone.service';
import { TimezoneEntityHelper } from '@main-application/management/components/system-configuration/components/timezone-entity.helper';
import { Observable, map, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export class Page<T> {
  count: number; // total number of items
  next: string; // URL of the next page
  previous: string; // URL of the previous page
  results: Array<T>; // items for the current page
}

export function queryPaginated<T>(
  http: HttpClient,
  baseUrl: string,
  urlOrFilter?: string | object
): Observable<Page<T>> {
  let params = new HttpParams();
  let url = baseUrl;

  if (typeof urlOrFilter === 'string') {
    // we were given a page URL, use it
    url = urlOrFilter;
  } else if (typeof urlOrFilter === 'object') {
    // we were given filtering criteria, build the query string
    Object.keys(urlOrFilter)
      .sort()
      .forEach(key => {
        const value = urlOrFilter[key];
        if (value !== null) {
          params = params.set(key, value.toString());
        }
      });
  }

  return http.get<Page<T>>(url, {
    params: params,
  });
}

@Injectable({
  providedIn: 'root',
})
export class RestapiServiceWithoutTimezone {
  constructor(protected http: HttpClient) {}

  /**
   * @title Repository Service
   *
   * TODO: Add pagination support. https://medium.com/@JeremyLaine/server-side-pagination-and-filtering-with-angular-6-280a7909e783
   */

  public getData<T>(route: string): Observable<T> {
    return this.http.get<T>(this.composeRoute(route, environment.apiUrl));
  }

  public getText(route: string): Observable<string> {
    return this.http.get(this.composeRoute(route, environment.apiUrl), {
      headers: new HttpHeaders({ Accept: 'text/html' }),
      responseType: 'text',
    });
  }

  public customPatchData<T>(route: string, body: unknown): Observable<T> {
    return this.http.patch<T>(this.composeRoute(route, environment.apiUrl), body);
  }

  public post<T>(route: string, body?: unknown): Observable<T> {
    return this.http.post<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public create<T>(route: string, body: unknown): Observable<T> {
    return this.http.post<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public associate<T>(route: string, body: unknown): Observable<T> {
    return this.http.post<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public update<T>(route: string, body: unknown): Observable<T> {
    return this.http.put<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public delete<T>(route: string, body: unknown = {}): Observable<T> {
    return this.http.delete<T>(this.composeRoute(route, environment.apiUrl), body);
  }

  public upload<T>(route: string, file: File): Observable<T> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<T>(this.composeRoute(route, environment.apiUrl), formData);
  }

  private composeRoute(route: string, environmentUrl: string) {
    return `${environmentUrl}/${route}`;
  }

  private generateHeaders() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class RestapiService extends RestapiServiceWithoutTimezone {
  constructor(http: HttpClient, private timezoneService: TimezoneService) {
    super(http);
  }

  public getData<T>(route: string, customTimezoneFixer: (data: T, timezone: number) => T = null): Observable<T> {
    return super
      .getData<T>(route)
      .pipe(
        this.timezoneService.addTimezoneForEntity(
          customTimezoneFixer ?? TimezoneEntityHelper.fixTimezoneForModelToClient
        )
      );
  }

  public customPatchData<T, TBody = any>(
    route: string,
    body: TBody,
    customTimezoneFixerRequest: (data: TBody, timezone: number) => TBody = null,
    customTimezoneFixerResponce: (data: T, timezone: number) => T = null
  ): Observable<T> {
    return this.timezoneService.currentTimezone$.pipe(
      map(timezone => (customTimezoneFixerRequest ?? TimezoneEntityHelper.fixTimezoneForModelToServer)(body, timezone)),
      mergeMap(newBody => super.customPatchData<T>(route, newBody)),
      this.timezoneService.addTimezoneForEntity(
        customTimezoneFixerResponce ?? TimezoneEntityHelper.fixTimezoneForModelToClient
      )
    );
  }

  public post<T, TBody = any>(
    route: string,
    body?: TBody,
    customTimezoneFixerRequest: (data: TBody, timezone: number) => TBody = null,
    customTimezoneFixerResponce: (data: T, timezone: number) => T = null
  ): Observable<T> {
    return this.timezoneService.currentTimezone$.pipe(
      map(timezone => (customTimezoneFixerRequest ?? TimezoneEntityHelper.fixTimezoneForModelToServer)(body, timezone)),
      mergeMap(newBody => super.post<T>(route, newBody)),
      this.timezoneService.addTimezoneForEntity(
        customTimezoneFixerResponce ?? TimezoneEntityHelper.fixTimezoneForModelToClient
      )
    );
  }

  public create<T, TBody = any>(
    route: string,
    body: TBody,
    customTimezoneFixerRequest: (data: TBody, timezone: number) => TBody = null,
    customTimezoneFixerResponce: (data: T, timezone: number) => T = null
  ): Observable<T> {
    return this.timezoneService.currentTimezone$.pipe(
      map(timezone => (customTimezoneFixerRequest ?? TimezoneEntityHelper.fixTimezoneForModelToServer)(body, timezone)),
      mergeMap(newBody => super.create<T>(route, newBody)),
      this.timezoneService.addTimezoneForEntity(
        customTimezoneFixerResponce ?? TimezoneEntityHelper.fixTimezoneForModelToClient
      )
    );
  }

  public update<T, TBody = any>(
    route: string,
    body: TBody,
    customTimezoneFixerRequest: (data: TBody, timezone: number) => TBody = null,
    customTimezoneFixerResponce: (data: T, timezone: number) => T = null
  ): Observable<T> {
    return this.timezoneService.currentTimezone$.pipe(
      map(timezone => (customTimezoneFixerRequest ?? TimezoneEntityHelper.fixTimezoneForModelToServer)(body, timezone)),
      mergeMap(newBody => super.update<T>(route, newBody)),
      this.timezoneService.addTimezoneForEntity(
        customTimezoneFixerResponce ?? TimezoneEntityHelper.fixTimezoneForModelToClient
      )
    );
  }
}
