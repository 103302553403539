export enum LoaderType {
  List = 'List',
  Board = 'Board',
  PieChart = 'PieChart',
  Kanban = 'Kanban',
  Comment_List = 'Comment_List',
  Details_Single = 'Details_Single',
  Recipients_Section = 'Recipients_Section',
  System_Config = 'System_Config',
}
