import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EIcon } from '@shared/enums/icon.enum';

@Injectable({
  providedIn: 'root',
})
export class CustomIconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  registerIcons() {
    Object.keys(EIcon).forEach(key => {
      const iconName = EIcon[key];
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icon/${iconName}.svg`)
      );
    });
  }
}
