import { Injectable } from '@angular/core';
import { RestapiService } from '@app/services/restapi.service';
import { RestRenewalModel, RestUnitRenewalTargetModel } from '@shared/interfaces/renevals.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RenewalsService {
  constructor(private restApiService: RestapiService) {}

  getRenewalDetails(renewalId: number): Observable<RestRenewalModel> {
    return this.restApiService.getData<RestRenewalModel>(`Renewals/${renewalId}`);
  }

  updateRenewalDetails(renewalModel: RestRenewalModel): Observable<RestRenewalModel> {
    return this.restApiService.update<RestRenewalModel>(`Renewals/${renewalModel.id}`, renewalModel);
  }

  getRenewalList(propertyId: number): Observable<RestRenewalModel[]> {
    return this.restApiService.getData<RestRenewalModel[]>(`Renewals/property/${propertyId}`);
  }

  renewalTargetList(propertyId: number): Observable<RestUnitRenewalTargetModel[]> {
    return this.restApiService.getData<RestUnitRenewalTargetModel[]>(`Renewals/RenewalTargets/property/${propertyId}`);
  }

  renewalTargetForUnitId(unitId: number): Observable<RestUnitRenewalTargetModel> {
    return this.restApiService.getData<RestUnitRenewalTargetModel>(`Renewals/RenewalTargetForUnitId/${unitId}`);
  }

  updateRenewalTarget(renewalTargetModels: RestUnitRenewalTargetModel[]): Observable<RestUnitRenewalTargetModel[]> {
    return this.restApiService.update<RestUnitRenewalTargetModel[]>(`Renewals/RenewalTargets`, renewalTargetModels);
  }
}
