import { createReducer, on } from '@ngrx/store';
import {
  clearUploadedFile,
  deleteFile,
  deleteFileFailed,
  deleteFileSuccess,
  uploadFile,
  uploadFileFail,
  uploadFileSuccess,
} from '@shared/actions/file-upload.actions';
import { UploadedFileItem } from '@shared/interfaces/uploaded-file-item.interface';
import { UpdateStatus } from '@shared/enums/update-status';

export const fileUploadFeatureKey = 'fileUpload';

export interface State {
  fileToUpload: File;
  fileToUploadLoading: boolean;
  uploadedFileItem: UploadedFileItem;
  deleteInProgress: boolean;
  deleteStatus: UpdateStatus;
}

export const initialState: State = {
  fileToUpload: null,
  fileToUploadLoading: false,
  uploadedFileItem: null,
  deleteInProgress: false,
  deleteStatus: null,
};

export const reducer = createReducer(
  initialState,
  /**
   * upload file
   */
  on(uploadFile, (state, action) => ({
    ...state,
    fileToUpload: action.file,
    fileToUploadLoading: true,
  })),
  on(uploadFileSuccess, (state, action) => ({
    ...state,
    uploadedFileItem: action.uploadedFileItem,
    fileToUploadLoading: false,
  })),
  on(uploadFileFail, (state, action) => ({
    ...state,
    uploadedFileItem: null,
    fileToUploadLoading: false,
  })),
  on(clearUploadedFile, (state, action) => ({
    ...state,
    fileToUpload: null,
    fileToUploadLoading: false,
    uploadedFileItem: null,
  })),

  /**
   * delete file
   */
  on(deleteFile, (state, action) => ({
    ...state,
    deleteInProgress: true,
    deleteStatus: UpdateStatus.UPDATE,
  })),
  on(deleteFileSuccess, (state, action) => ({
    ...state,
    deleteInProgress: false,
    deleteStatus: UpdateStatus.DELETED,
  })),
  on(deleteFileFailed, (state, action) => ({
    ...state,
    deleteInProgress: false,
    deleteStatus: UpdateStatus.ERROR,
  }))
);
